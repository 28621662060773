import React, { useEffect, useState } from "react";
import { Typography, List, Paper } from "@mui/material";
import { format } from "date-fns";

const RequestReport = ({ data = [] }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        if (data.length > 0) setIsLoading(false);
    }, [data]);
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, []);
    return (
        !isLoading && (
            <Paper
                elevation={10}
                sx={{
                    px: windowSize.width > 960 ? 5 : 2,
                    py: windowSize.width > 960 ? 4 : 2,
                    mb: windowSize.width > 960 ?  15 : 8,
                    borderRadius: 4,
                }}
            >
                <Typography variant="h5">Requests History</Typography>
                <List>
                    {data.map((item, index) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: 16,
                            }}
                            key={index}
                        >
                            <Paper
                                elevation={5}
                                sx={{
                                    borderRadius: 3,
                                    flex: 1,
                                }}
                                className={
                                    item.status === "approved"
                                        ? "green-background"
                                        : item.status === "in_progress"
                                        ? "orange-background"
                                        : "red-background "
                                }
                            >
                                <Typography
                                    sx={{
                                        pr: 5,
                                        pl: 2,
                                        py: 2,
                                        color:
                                            item.status === "rejected"
                                                ? "white"
                                                : "",
                                    }}
                                >
                                    On{" "}
                                    {format(
                                        new Date(item.req_created_at),
                                        "yyy.MM.dd "
                                    )}
                                    at{" "}
                                    {format(
                                        new Date(item.req_created_at),
                                        "hh:mm aaa"
                                    )}
                                    , a request was initiated with the reason '
                                    {item.reason}'.
                                    <br /> The request is currently marked as '
                                    {item.status.includes("_")
                                        ? item.status.replace("_", " ")
                                        : item.status}
                                    '.
                                    <br />
                                    {item.status === "in_progress"
                                        ? "You have requested a status change, dated on " +
                                          format(
                                              new Date(item.status_timestamp),
                                              "yyy.MM.dd "
                                          ) +
                                          " at " +
                                          format(
                                              new Date(item.status_timestamp),
                                              "hh:mm aaa"
                                          ) +
                                          "."
                                        : ""}
                                </Typography>
                            </Paper>
                        </div>
                    ))}
                </List>
            </Paper>
        )
    );
};

export default RequestReport;
