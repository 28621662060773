import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, Typography } from "@mui/material";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isWeekend,
  getMonth,
  getYear,
  getDay,
} from "date-fns";
import { useAuth } from "../../utils/AuthContext";
import api from "../../utils/api";
import { API_ENDPOINTS, sec2time, stringToColor } from "../../utils/vars";
import Loading from "../../utils/Loading";
import LiveClock from "../../utils/LiveClock";
import MonthGraph from "../../utils/MonthGraph";

const AdminMS = () => {
  const { user } = useAuth();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthData, setMonthData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const [graphData, setSeries] = useState({
    workingTime: [],
    breakTime: [],
  });
  const handlePrevMonth = () => {
    const newDate = subMonths(currentMonth, 1);
    setCurrentMonth(newDate);

    getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
    getGraphData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
  };
  const handleNextMonth = () => {
    const newDate = addMonths(currentMonth, 1);
    setCurrentMonth(newDate);

    getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
    getGraphData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
  };
  const getRowId = (row) => row.id;
  const getMonthData = async (user, month, year) => {
    setIsLoading(true);
    if (user) {
      try {
        const data = {
          user_id: user,
          month: month,
          year: year,
        };
        const response = await api.post(
          API_ENDPOINTS.GET_USERS_MONTH_STATISTICS,
          data
        );
        setMonthData(
          response.data?.users_statistics ? response.data.users_statistics : []
        );

        setUsers(response.data.users);
        setIsLoading(false);
      } catch (error) {
        console.error("User month data error:", error);
      }
    }
  };
  const getGraphData = async (user, month, year) => {
    if (user) {
      try {
        const data = {
          user_id: user,
          month: month,
          year: year,
        };
        const response = await api.post(
          API_ENDPOINTS.GET_ALL_USERS_MONTH_DATA,
          data
        );
        resolveData(
          { ...response.data?.data_by_user },
          response.data.users,
          new Date(year, month - 1)
        );
      } catch (error) {
        console.error("User month data error:", error);
      }
    }
  };
  const resolveData = (data, _users, _date = currentMonth) => {
    const startDate = startOfMonth(_date);
    const endDate = endOfMonth(_date);
    const allDatesInMonth = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
    const labels = [];
    const series = [];
    let labelsSet = false;
    Object.keys(data)?.map((userID) => {
      const seriesData = {
        data: [],
        label: "",
        color: "",
        showMark: false,
      };
      allDatesInMonth.map((day, index) => {
        let currentUser = [];
        _users.forEach((user) => {
          if (user[0] === parseInt(userID)) {
            currentUser = user;
            return;
          }
        });
        if (currentUser.length > 0) {
          seriesData.label = currentUser[1];
          seriesData.color = stringToColor(currentUser[1]);
          const dayOfWeek = getDay(day);
          const isSunday = dayOfWeek === 0;
          const isSaturday = dayOfWeek === 6;
          if (!isSunday && !isSaturday) {
            if (!labelsSet) labels.push(format(new Date(day), "dd MMM"));

            if (data[userID][index + 1]?.working_time !== undefined)
              seriesData.data.push(
                data[userID][index + 1]?.working_time / 3600
              );
            else {
              seriesData.data.push(null);
            }
          }
        }
        return 0;
      });
      labelsSet = true;

      series.push(seriesData);
      return 0;
    });
    setSeries(series);
    setXAxisLabels(labels);
  };
  const handleRefreshData = () => {
    getMonthData(
      user?.data.id,
      getMonth(currentMonth) + 1,
      getYear(currentMonth)
    );
  };
  useEffect(() => {
    getMonthData(
      user?.data.id,
      getMonth(currentMonth) + 1,
      getYear(currentMonth)
    );

    getGraphData(
      user?.data.id,
      getMonth(currentMonth) + 1,
      getYear(currentMonth)
    );
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [user]);
  function countBusinessDaysInMonth(year, month) {
    const firstDayOfMonth = startOfMonth(new Date(year, month - 1, 1));
    const lastDayOfMonth = endOfMonth(new Date(year, month - 1, 1));

    const daysOfMonth = eachDayOfInterval({
      start: firstDayOfMonth,
      end: lastDayOfMonth,
    });

    let businessDayCount = 0;

    for (const day of daysOfMonth) {
      if (!isWeekend(day)) {
        businessDayCount++;
      }
    }

    return businessDayCount;
  }
  const columns = [
    { field: "user_id", headerName: "User", width: 200, sortable: false },
    {
      field: "business_days",
      headerName: "Business days",
      width: windowSize.width < 1800 ? 150 : 200,
    },
    {
      field: "worked_days",
      headerName: "Worked days",
      width: windowSize.width < 1800 ? 150 : 200,
    },
    {
      field: "hourly_rate",
      headerName: "Hourly rate",
      width: windowSize.width < 1800 ? 150 : 200,
    },
    {
      field: "total_earn",
      headerName: "Total earn",
      width: windowSize.width < 1800 ? 150 : 200,
    },
    {
      field: "working_time",
      headerName: "Working Time",
      width: windowSize.width < 1800 ? 150 : 200,
    },
    {
      field: "break_time",
      headerName: "Break Time",
      width: windowSize.width < 1800 ? 150 : 200,
    },
    {
      field: "average_working_time",
      headerName: "Avg. working time",
      width:
        windowSize.width < 2100 ? (windowSize.width < 1800 ? 160 : 200) : 250,
    },
    {
      field: "average_break_time",
      headerName: "Avg. break time",
      width:
        windowSize.width < 2100 ? (windowSize.width < 1800 ? 160 : 200) : 250,
    },
    {
      field: "average_clock_in_time",
      headerName: "Avg. clock in timestamp",
      width:
        windowSize.width < 2100 ? (windowSize.width < 1800 ? 160 : 200) : 250,
    },
    {
      field: "average_clock_out_time",
      headerName: "Avg. clock out timestamp",
      width:
        windowSize.width < 2100 ? (windowSize.width < 1800 ? 160 : 200) : 250,
    },
  ];
  const rows = monthData?.map((userData) => {
    let _user = [];
    users.forEach((user) => {
      if (user[0] === userData.id) {
        _user = user;
        return;
      }
    });
    return {
      id: userData?.id,
      business_days: countBusinessDaysInMonth(
        getYear(currentMonth),
        getMonth(currentMonth)
      ),
      working_time: sec2time(userData?.working_time),
      break_time: sec2time(userData?.break_time),
      average_working_time: sec2time(userData?.avg_working_time),
      worked_days:
        _user[3] > 0
          ? (userData?.working_time / (_user[3] * 3600)).toFixed(2)
          : "N/A",
      hourly_rate: _user[4] ? _user[4]?.toString() + " " + _user[5] : "N/A",
      total_earn: _user[4]
        ? ((_user[4] * userData?.working_time) / 3600).toFixed(2)?.toString() +
          " " +
          _user[5]
        : "N/A",
      average_break_time: sec2time(userData?.avg_break_time),
      average_clock_in_time: userData?.avg_clock_in,
      average_clock_out_time: userData?.avg_clock_out,
      user_id: userData?.name,
    };
  });

  return isLoading ? (
    <Loading />
  ) : (
    <div className="table-container">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        marginY={15}
        className="month-selector-container"
      >
        <Button
          sx={{
            fontWeight: "bold",
          }}
          onClick={handlePrevMonth}
        >
          Prev Month
        </Button>
        <Typography variant="h4">
          {format(currentMonth, "MMMM yyyy")}
        </Typography>
        <Button
          sx={{
            fontWeight: "bold",
          }}
          onClick={handleNextMonth}
        >
          Next Month
        </Button>
      </Grid>

      <Typography fontWeight={"bold"} className="info-text">
        ***This statistics does not include the current day***
      </Typography>
      <Grid
        display="grid"
        alignItems="center"
        justifyContent="start"
        gridTemplateColumns="repeat(2, 1fr)"
        className="live-clock-container"
      >
        <Button
          sx={{
            fontWeight: "bold",
            width: 150,
            my: 2,
          }}
          variant="contained"
          color="primary"
          className="refresh-data-button"
          onClick={handleRefreshData}
        >
          Refresh Data
        </Button>
        <LiveClock />
      </Grid>

      <div>
        <DataGrid
          className={["table-toolbar"]}
          rows={rows}
          columns={columns}
          pageSize={10}
          getRowId={getRowId}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 25, 50]}
          sx={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "rgba(10,10,10,.9)",
            borderRadius: 4,
          }}
        />
      </div>
      {graphData && xAxisLabels.length > 0 ? (
        <MonthGraph xLabels={xAxisLabels} series={graphData} />
      ) : (
        <Fragment />
      )}
    </div>
  );
};

export default AdminMS;
