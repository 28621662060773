import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Button,
    Typography,
    TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { format, isEqual } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enqueueSnackbar } from "notistack";
import api from "./api";
import { API_ENDPOINTS } from "./vars";
import { useAuth } from "./AuthContext";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";

const RequestStatusChangeModal = ({ open, onClose, item, refreshRequests }) => {
    const { user } = useAuth();
    const [newTime, setNewTime] = useState();
    const [reason, setReason] = useState("");
    const handleSelectTime = (value) => {
        setNewTime(value);
    };
    const handleSubmitRequest = async (id, date) => {
        if (!reason.trim()) {
            enqueueSnackbar("You need to provide a reason.", {
                variant: "warning",
            });

            return;
        }
        if (isEqual(new Date(item.changed_at), new Date(date))) {
            enqueueSnackbar("Select a different time.", { variant: "warning" });

            return;
        }
        try {
            const response = await api.post(
                API_ENDPOINTS.SUBMIT_STATUS_REQUEST,
                {
                    user_id: id,
                    status_change_id: item?.id,
                    date: date,
                    reason: reason,
                    original_timestamp: item?.changed_at,
                }
            );
            if (response.data.status) {
                enqueueSnackbar(response.data?.message, {
                    variant: "success",
                });
                refreshRequests();
                onClose();
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: "warning",
                });
                onClose();
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    useEffect(() => {
        setTimeout(() => {
            setNewTime(new Date(item.changed_at));
            setReason("");
        }, 200);
        // eslint-disable-next-line
    }, [open]);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle textAlign={"center"} fontWeight={600}>
                Request new time
            </DialogTitle>
            <Divider />
            <DialogContent>
                {Object.keys(item).length > 0 ? (
                    <Fragment>
                        <Typography variant="h5" fontWeight={500}>
                            Change{" "}
                            {item?.current_status === "clocked_in"
                                ? ` clocked in at ${format(
                                      new Date(item?.changed_at),
                                      "hh:mm aa"
                                  )}`
                                : item?.current_status === "break"
                                ? ` break at ${format(
                                      new Date(item?.changed_at),
                                      "hh:mm aa"
                                  )}`
                                : ` clocked out at ${format(
                                      new Date(item?.changed_at),
                                      "hh:mm aa"
                                  )}`}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <StaticTimePicker
                                orientation="landscape"
                                value={newTime}
                                defaultValue={new Date(item.changed_at)}
                                onChange={handleSelectTime}
                            />
                        </LocalizationProvider>
                        <TextField
                            label="Reason"
                            variant="outlined"
                            multiline
                            fullWidth
                            value={reason}
                            onChange={(ev) => setReason(ev.target.value)}
                        />
                    </Fragment>
                ) : (
                    ""
                )}
            </DialogContent>
            <div
                style={{
                    padding: "16px",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 20,
                }}
            >
                <Button
                    onClick={() => handleSubmitRequest(user?.data.id, newTime)}
                    color="info"
                    variant="contained"
                    endIcon={<ScheduleSendIcon />}
                >
                    Submit request
                </Button>
                <Button
                    onClick={onClose}
                    color="error"
                    variant="contained"
                    endIcon={<CloseIcon />}
                >
                    Cancel
                </Button>
            </div>
        </Dialog>
    );
};

export default RequestStatusChangeModal;
