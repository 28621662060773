import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, Typography } from "@mui/material";
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    getDay,
    getMonth,
    isAfter,
    isToday,
    parse,
    isWeekend,
    getYear,
    isWithinInterval,
} from "date-fns";
import { useAuth } from "../utils/AuthContext";
import api from "../utils/api";
import { API_ENDPOINTS, sec2time, time2sec } from "../utils/vars";
import PopUp from "../utils/PopUp";
import Loading from "../utils/Loading";
import TimeLegendModal from "../utils/TimeLegendModal";
import LiveClock from "../utils/LiveClock";
import RequestFreeDaysModal from "../utils/RequestFreeDaysModal";

const TimeManagement = ({ dashboard = false, userStatus = "" }) => {
    const { user } = useAuth();
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [monthData, setMonthData] = useState({});
    const [showPopUp, setShowPopUp] = useState(false);
    const [freeDaysModal, setFreeDaysModal] = useState(false);
    const [popUpData, setPopUpData] = useState({ title: "", content: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [workingHours, setWorkingHours] = useState(8);
    const [status, setStatus] = useState(userStatus);
    const [freeDays, setFreeDays] = useState([]);
    const [freeDaysRows, setFreeDaysRows] = useState([]);
    const [allDatesInMonth, setAllDatesInMonth] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [openLegend, setOpenLegend] = useState(false);
    const handlePrevMonth = () => {
        const newDate = subMonths(currentMonth, 1);
        setCurrentMonth(newDate);

        getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
    };
    const handleNextMonth = () => {
        const newDate = addMonths(currentMonth, 1);
        setCurrentMonth(newDate);

        getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
    };
    const getRowId = (row) => row.id;
    const getMonthData = async (user, month, year) => {
        setIsLoading(true);
        if (user) {
            try {
                const data = {
                    user_id: user,
                    month: month,
                    year: year,
                };
                const response = await api.post(
                    API_ENDPOINTS.GET_USER_MONTH_DATA,
                    data
                );
                setFreeDays(response.data?.free_days);
                setMonthData({ ...response.data?.data_by_user });
                setIsLoading(false);
            } catch (error) {
                console.error("User month data error:", error);
            }
        }
    };
    const handleCellClick = async (ev) => {
        if (ev?.id) {
            const date = parse(ev?.field, "EEE dd MMM", new Date());
            const _isToday = isToday(date, currentMonth);
            let _popUpData = {
                title: "Date: " + ev.field,
                isData: false,
            };
            if (!_isToday && ev.formattedValue.includes(",")) {
                const times = ev.formattedValue.split(",");
                _popUpData = {
                    ..._popUpData,
                    breakTime: times[1],
                    workingTime: times[0],
                    isData: true,
                };
            } else if (_isToday) {
                _popUpData = {
                    ..._popUpData,
                    id: ev?.id,
                    actualDay: _isToday,
                    status: status,
                    isData: true,
                };
            }
            if (isWeekend(date)) {
                _popUpData = {
                    ..._popUpData,
                    isWeekend: true,
                };
            }
            setPopUpData(_popUpData);
            setShowPopUp(true);
        }
    };
    const handleRefreshData = () => {
        getMonthData(
            user?.data.id,
            getMonth(currentMonth) + 1,
            getYear(currentMonth)
        );
    };
    const getStatus = async (id) => {
        if (id) {
            try {
                const response = await api.post(API_ENDPOINTS.GET_STATUS, {
                    user_id: id,
                });
                setStatus(response.data.status);
                setWorkingHours(response.data.working_hours);
            } catch (error) {
                // Handle error (e.g., show error message)
                console.error("Error changing status:", error);
            }
        }
    };
    const requestFreeDays = () => {
        setFreeDaysModal(true);
    };
    useEffect(() => {
        getMonthData(
            user?.data.id,
            getMonth(currentMonth) + 1,
            getYear(currentMonth)
        );
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        async function fetchStatus() {
            getStatus(user?.data?.id);
        }
        fetchStatus();
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, [user]);
    useEffect(() => {
        setStatus(userStatus);
    }, [userStatus]);

    useEffect(() => {
        const startDate = startOfMonth(currentMonth);
        const endDate = endOfMonth(currentMonth);
        const _allDatesInMonth = eachDayOfInterval({
            start: startDate,
            end: endDate,
        });
        const _columns = [
            {
                field: "user_id",
                headerName: "User",
                width: 130,
                sortable: false,
            },

            ..._allDatesInMonth.map((date) => {
                return {
                    field: format(date, "EEE dd MMM"),
                    headerName:
                        windowSize?.width < 2330
                            ? format(date, "d ")
                            : format(date, "dd MMM"),
                    width: windowSize?.width < 2330 ? 50 : 70,
                    sortable: false,
                };
            }),
        ];
        const freeRows = Object.keys({ ...freeDays }).map(() => {
            let i = 1;
            const newRow = { id: user?.data.id };
            newRow.user_id = user?.data.name;
            _allDatesInMonth.forEach((date) => {
                const formattedDate = format(date, "EEE dd MMM");
                newRow[formattedDate] = freeDays[i]?.working_time
                    ? sec2time(freeDays[i]?.working_time) +
                      "," +
                      sec2time(freeDays[i]?.break_time)
                    : "";
                i++;
            });
            return newRow;
        });
        const _rows = Object.keys(monthData).map(() => {
            let i = 1;
            const newRow = { id: user?.data.id };
            newRow.user_id = user?.data.name;
            allDatesInMonth.forEach((date) => {
                const formattedDate = format(date, "EEE dd MMM");
                newRow[formattedDate] = monthData[i]?.working_time
                    ? sec2time(monthData[i]?.working_time) +
                      "," +
                      sec2time(monthData[i]?.break_time)
                    : "";
                i++;
            });
            return newRow;
        });
        setAllDatesInMonth(_allDatesInMonth);
        setColumns(_columns);
        setFreeDaysRows(freeRows);
        setRows(_rows);
        // console.log('new month... please update this fucking shit or i will die here')
        
        // eslint-disable-next-line
    }, [monthData, currentMonth]);
    return isLoading ? (
        <Loading />
    ) : (
        <div className="table-container">
            {!dashboard && (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    marginY={15}
                    className="month-selector-container"
                >
                    <Button
                        sx={{
                            fontWeight: "bold",
                        }}
                        onClick={handlePrevMonth}
                    >
                        Prev Month
                    </Button>
                    <Typography variant="h4">
                        {format(currentMonth, "MMMM yyyy")}
                    </Typography>
                    <Button
                        sx={{
                            fontWeight: "bold",
                        }}
                        onClick={handleNextMonth}
                    >
                        Next Month
                    </Button>
                </Grid>
            )}

            <Grid
                display="flex"
                alignItems={"center"}
                justifyContent={dashboard ? "space-between" : "flex-end"}
            >
                {dashboard && (
                    <Button
                        sx={{
                            fontWeight: "bold",
                            width: 150,
                            my: 2,
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleRefreshData}
                    >
                        Refresh Data
                    </Button>
                )}
                <Button
                    sx={{
                        fontWeight: "bold",
                        width: 150,
                        my: 2,
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenLegend(true)}
                >
                    Show Legend
                </Button>
            </Grid>

            {!dashboard && (
                <Grid
                    display="grid"
                    alignItems="center"
                    justifyContent="start"
                    gridTemplateColumns="repeat(2, 1fr)"
                    className="live-clock-container"
                >
                    <Button
                        sx={{
                            fontWeight: "bold",
                            width: 150,
                            my: 2,
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleRefreshData}
                        className="refresh-data-button"
                    >
                        Refresh Data
                    </Button>
                    <LiveClock />
                </Grid>
            )}

            <div style={{ marginBottom: 20 }}>
                <DataGrid
                    className={["table-toolbar", "table-toolbar-user"]}
                    // rows={rows}
                    rows={rows.length > 0 ? rows : freeDaysRows}
                    columns={columns}
                    pageSize={10}
                    getRowId={getRowId}
                    autoHeight
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableVirtualization={true}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 1 },
                        },
                    }}
                    pageSizeOptions={[1]}
                    onCellClick={handleCellClick}
                    sx={{
                        cursor: "pointer",
                        color: "white",
                        borderRadius: 4,
                        backgroundColor: "rgba(10,10,10,.9)",
                    }}
                    getCellClassName={(params) => {
                        const date = parse(
                            params?.field,
                            "EEE dd MMM",
                            currentMonth
                        );
                        const dayOfWeek = getDay(date);
                        const isSunday = dayOfWeek === 0;
                        const isSaturday = dayOfWeek === 6;
                        for (const interval of freeDays) {
                            const startDate = new Date(interval[2]);
                            const endDate = new Date(interval[3]);

                            const isFreeDay = isWithinInterval(date, {
                                start: startDate,
                                end: endDate,
                            });
                            // console.log(date, startDate, endDate, isFreeDay);
                            if (isFreeDay) {
                                if (isSaturday || isSunday) {
                                    return ["grey-background", "cell"];
                                } else {
                                    if(interval[5] === 'approved'){

                                        return ["blue-accepted-background", "cell"];
                                    }
                                    
                                    if(interval[5] === 'rejected'){

                                        return ["purple-rejected-background ", "cell"];
                                    }
                                    if(interval[5] === 'in_progress'){

                                        return ["blue-in-progress-background", "cell"];
                                    }
                                    
                                }
                            }
                        }
                        if (
                            params.formattedValue &&
                            params.formattedValue.includes(",")
                        ) {
                            const times = params.formattedValue.split(",");

                            const _isToday = isToday(date, currentMonth);

                            if (_isToday) {
                                switch (status) {
                                    case "clocked_in":
                                        return [
                                            "green-background",
                                            "is-clocked_in ",
                                        ];
                                    case "break":
                                        return ["yellow-background", "cell"];
                                    case "clocked_out":
                                        return time2sec(times[0]) >=
                                            workingHours * 3600
                                            ? ["green-background", "cell"]
                                            : ["red-background", "cell"];

                                    default:
                                        return [""];
                                }
                            } else if (
                                time2sec(times[0]) <= workingHours * 3600 &&
                                !_isToday
                            ) {
                                return ["red-background", "cell"];
                            } else {
                                return ["green-background", "cell"];
                            }
                        } else {
                            const isDateInFuture = isAfter(date, currentMonth);
                            if (isSaturday || isSunday) {
                                return ["grey-background", "cell"];
                            } else if (isDateInFuture) {
                                return ["cell"];
                            }
                        }
                    }}
                />
            </div>

            <Grid
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: 5,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ fontWeight: 700 }}
                    onClick={() => requestFreeDays(user?.data.id)}
                >
                    Request free days
                </Button>
            </Grid>
            <TimeLegendModal
                open={openLegend}
                onClose={() => setOpenLegend(false)}
            />
            <PopUp
                onClose={() => setShowPopUp(false)}
                open={showPopUp}
                data={popUpData}
            />
            <RequestFreeDaysModal
                open={freeDaysModal}
                handleClose={() => setFreeDaysModal(false)}
            />
        </div>
    );
};

export default TimeManagement;
