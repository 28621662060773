import React, {useEffect, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { Divider, Paper, Typography, Box } from "@mui/material";
import { sec2time } from "./vars";

const MonthGraph = ({ xLabels, series }) => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const formatTime = (value) => {
        return sec2time(value * 3600);
    };
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);

        // eslint-disable-next-line
    }, []);
    return (
        <Paper
            sx={{
                my: 5,
                px: windowSize.width > 960 ? 5 : 2,
                py: 3,
                borderRadius: 4,
            }}
            elevation={5}
        >
            <Typography variant="h6">Month Chart</Typography>
            <Divider />
            <Box sx={{ width: "100%" }} className="chart-ms">
                <LineChart
                    height={300}
                    series={series?.map(row => {
                        return(
                            {
                                ...row,
                                valueFormatter: formatTime,
                            }
                        )
                    })}
                    xAxis={[
                        {
                            label: windowSize.width > 960 ? "Business day" : "",
                            scaleType: "point",
                            data: xLabels,
                        },
                    ]}
                    yAxis={[
                        {
                            tickMaxStep: 2,
                            min: series.length > 0 ? .1 : 0,
                            label: "Hours",
                        }
                    ]}
                    sx={{
                        "--ChartsLegend-rootSpacing": "100px",
                    }}
                    legend={{
                        hidden: (windowSize.width < 960 && series.length > 2),
                    }}
                />
            </Box>
        </Paper>
    );
};

export default MonthGraph;
