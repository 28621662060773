import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';

const ConfirmDialog = ({ open, onClose, onConfirm, message }) => {
    const messages = message.split("?")
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign={'center'} fontWeight={600} >Confirmation</DialogTitle>
      <Divider/>
      <DialogContent sx={{pb:1}}>{messages[0]} ?</DialogContent>
      <DialogContent sx={{py:0, pb:3}} >{messages[1]}</DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" variant='contained' startIcon={<CheckIcon/>}>
          Confirm
        </Button>
        <Button onClick={onClose} color="error" variant='contained' endIcon={<CloseIcon/>}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
