export const API_ENDPOINTS = {
    REGISTER: "/register",
    LOGIN: "/login",
    CHANGE_STATUS: "/change-status",
    GET_STATUS: "/get-status",
    GET_TIMES: "/get-times",
    GET_USER_MONTH_DATA: "/get-monthdata",
    GET_ALL_USERS_MONTH_DATA: "/get-all-monthdata",
    GET_USER_MONTH_STATISTICS: "/get-user-statistics",
    GET_USERS_MONTH_STATISTICS: "/get-users-statistics",
    SAVE_REPORT: "/save-report",
    GET_USER_REPORTS: "/get-user-reports",
    GET_USER_REPORT: "/get-user-report",
    UPDATE_REPORT: "/update-report",
    GET_USERS: "/get-users",
    REQUEST_NEW_PASSWORD: "/request-new-password",
    RESET_PASSWORD: "/reset-password",
    DELETE_USER: "/delete-user",
    EDIT_USER: "/edit-user",
    UPLOAD_WEEK_REPORT_FILE: "/upload-file",
    GET_USER_FILES: "/get-user-files",
    DELETE_FILE: "/delete-file",
    DOWNLOAD_FILE: "/download",
    STATUS_HISTORY: "/get-today-history",
    SUBMIT_STATUS_REQUEST: "/status-change-request",
    REQUESTS_REPORT: "/get-change-requests",
    CHECK_TOKEN: "/check_token",
    LOGOUT: "/logout",
    GET_ALL_USERS_REQUESTS_REPORT: '/get-all-change-requests',
    SUBMIT_REQUEST_RESPONSE:'/submit-request-response',
    CHANGE_ACCOUNT_STATUS:'/change-account-status',
    REQUEST_FREE_DAYS:'/request-free-days',
    DOWNLOAD_XLSX_MONTH_REPORT:'/xlsx-month-report',
    GET_ALL_FREE_DAYS_REQUESTS:'/get-all-free-days-requests',
    SUBMIT_FREE_DAYS_REQUEST_RESPONSE:'/submit-free-days-request-response',
    GET_USER_HR_HISTORY:'/get-hr-history',
    UPDATE_USER_HR_HISTORY:'/update-hr-history'
};

export const API_AUTH = {
    USERNAME: "clock-tron",
    PASSWORD: "**97W^[>[fm+H2d}",
    SECRET: "c2800916-521a-11ee-be56-0242ac120002",
};

export const sec2time = (timeInSeconds) => {
    timeInSeconds = timeInSeconds.toString();
    timeInSeconds =
        timeInSeconds[0] === "-" ? timeInSeconds.substring(1) : timeInSeconds;
    var pad = function (num, size) {
            return ("000" + num).slice(size * -1);
        },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60);

    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
};

export const time2sec = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

export const ONE_DAY_IN_SECONDS = 28800;
export const INACTIVITY_TIMEOUT = 2; // IN HOURS
export const beautyStatus = (status) => {
    switch (status) {
        case "clocked_in":
            return "Online";
        case "break":
            return "Break";
        case "clocked_out":
            return "Offline";
        default:
            return "Offline";
    }
};

export const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
};

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            height: 48,
            width: 48,
            fontWeight: "bold",
            textTransform: "uppercase",
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
}
