import axios from 'axios';
import { API_AUTH } from './vars';
const api = axios.create({
  
    baseURL:'https://api.clock.tron.ro',
    auth: {
        username: API_AUTH.USERNAME,
        password: API_AUTH.PASSWORD,
      },
});

export default api;
