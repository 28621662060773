import React from "react";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import RightMenu from "./RightMenu";
import { useAuth } from "./AuthContext";
import Loading from "./Loading";

const Layout = ({ children }) => {
    const { loading } = useAuth();
    const [rightMenuOpen, setRightMenuOpen] = React.useState(false);

    const toggleRightMenu = () => {
        setRightMenuOpen(!rightMenuOpen);
    };
    if (loading) return <Loading />;
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
            }}
        >
            <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleRightMenu}
                sx={{
                    position: "fixed",
                    marginRight: 3,
                    marginTop: 2,
                }}
            >
                <MenuIcon />
            </IconButton>
            <RightMenu open={rightMenuOpen} onClose={toggleRightMenu} />
            {children}
        </div>
    );
};

export default Layout;
