import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { AuthProvider } from "./utils/AuthContext";
import Layout from "./utils/Layout";
import TimeManagement from "./pages/TimeManagement";
import MonthSummary from "./pages/MonthSummary";
import WorkReports from "./pages/WorkReports";
import AdminMS from "./pages/admin/AdminMS";
import AdminTM from "./pages/admin/AdminTM";
import AdminWR from "./pages/admin/AdminWR";
import AdminMA from "./pages/admin/AdminMA";
import ResetPassword from "./pages/ResetPassword";
import { SnackbarProvider } from "notistack";
import AdminSR from "./pages/admin/AdminSR";
import { INACTIVITY_TIMEOUT } from "./utils/vars";
import ConfirmDialog from "./utils/ConfirmDialog";

function App() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [inactivityTimeout, setInactivityTimeout] = useState(0);
    const [showInactivityPopup, setShowInactivityPopup] = useState(false);
    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimeout);
        setInactivityTimeout(
            setTimeout(
                () => setShowInactivityPopup(true),
                INACTIVITY_TIMEOUT * 60 * 60 * 1500
            )
        );
    };

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();

        // Add event listeners to reset the timer on user interaction
        document.addEventListener("mousemove", resetInactivityTimer);
        document.addEventListener("keypress", resetInactivityTimer);

        // Initial setup of the inactivity timer
        resetInactivityTimer();

        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, []);

    return (
        <Router>
            <AuthProvider>
                <SnackbarProvider
                    maxSnack={windowSize.width > 960 ? 5 : 3}
                    autoHideDuration={windowSize.width > 960 ? 3000 : 2000}
                    anchorOrigin={{
                        vertical: windowSize.width > 960 ? "top" : "bottom",
                        horizontal: "left",
                    }}
                    className="snackbar-notification"
                >
                    <Routes>
                        <Route index path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/dashboard"
                            element={
                                <Layout>
                                    <Dashboard />
                                </Layout>
                            }
                        />
                        <Route
                            path="/time-management"
                            element={
                                <Layout>
                                    <TimeManagement />
                                </Layout>
                            }
                        />
                        <Route
                            path="/month-summary"
                            element={
                                <Layout>
                                    <MonthSummary />
                                </Layout>
                            }
                        />
                        <Route
                            path="/work-reports"
                            element={
                                <Layout>
                                    <WorkReports />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin-time-management"
                            element={
                                <Layout>
                                    <AdminTM />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin-month-summary"
                            element={
                                <Layout>
                                    <AdminMS />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin-work-reports"
                            element={
                                <Layout>
                                    <AdminWR />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin-manage-accounts"
                            element={
                                <Layout>
                                    <AdminMA />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin-status-requests"
                            element={
                                <Layout>
                                    <AdminSR />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reset-password/:token"
                            element={<ResetPassword />}
                        />
                    </Routes>
                    <ConfirmDialog
                        open={showInactivityPopup}
                        onClose={() => setShowInactivityPopup(false)}
                        message={
                            "You we're inactive for more than two hours, do you agree to refresh the page?"
                        }
                        onConfirm={() => window.location.reload()}
                    />
                </SnackbarProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
