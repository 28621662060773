import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, Typography } from "@mui/material";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  getDay,
  getMonth,
  isAfter,
  isToday,
  parse,
  isWeekend,
  getYear,
  isWithinInterval,
} from "date-fns";
import { useAuth } from "../../utils/AuthContext";
import api from "../../utils/api";
import { API_ENDPOINTS, sec2time, time2sec } from "../../utils/vars";
import PopUp from "../../utils/PopUp";
import Loading from "../../utils/Loading";
import TimeLegendModal from "../../utils/TimeLegendModal";
import LiveClock from "../../utils/LiveClock";

const AdminTM = () => {
  const { user } = useAuth();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthData, setMonthData] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState({ title: "", content: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [freeDays, setFreeDays] = useState([]);
  const [users, setUsers] = useState({});
  const [openLegend, setOpenLegend] = useState(false);
  const [freeDaysRows, setFreeDaysRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const handlePrevMonth = () => {
    const newDate = subMonths(currentMonth, 1);
    setCurrentMonth(newDate);

    getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
  };
  const handleNextMonth = () => {
    const newDate = addMonths(currentMonth, 1);
    setCurrentMonth(newDate);

    getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
  };
  const getRowId = (row) => row.id;
  const getMonthData = async (user, month, year) => {
    setIsLoading(true);
    if (user) {
      try {
        const data = {
          user_id: user,
          month: month,
          year: year,
        };
        const response = await api.post(
          API_ENDPOINTS.GET_ALL_USERS_MONTH_DATA,
          data
        );
        setFreeDays(response.data?.free_days);
        setMonthData({ ...response.data?.data_by_user });
        setUsers(response.data?.users);
        setIsLoading(false);
      } catch (error) {
        console.error("User month data error:", error);
      }
    }
  };
  const handleCellClick = (ev) => {
    if (ev?.id) {
      const date = parse(ev?.field, "EEE dd MMM", new Date());
      const _isToday = isToday(date, currentMonth);
      let _popUpData = {
        title: "Date: " + ev.field,
        isData: false,
      };
      if (!_isToday && ev.formattedValue.includes(",")) {
        const times = ev.formattedValue.split(",");
        _popUpData = {
          ..._popUpData,
          breakTime: times[1],
          workingTime: times[0],
          isData: true,
        };
      } else if (_isToday) {
        let usersStatus = "";
        users.forEach((user) => {
          if (user[0] === ev?.id) {
            usersStatus = user[2];
          }
        });
        _popUpData = {
          ..._popUpData,
          id: ev?.id,
          actualDay: _isToday,
          status: usersStatus,
          isData: true,
        };
      }
      if (isWeekend(date)) {
        _popUpData = {
          ..._popUpData,
          isWeekend: true,
        };
      }
      setPopUpData(_popUpData);
      setShowPopUp(true);
    }
  };
  const handleRefreshData = () => {
    getMonthData(
      user?.data.id,
      getMonth(currentMonth) + 1,
      getYear(currentMonth)
    );
  };
  useEffect(() => {
    const _startDate = startOfMonth(currentMonth);
    const _endDate = endOfMonth(currentMonth);
    const _allDatesInMonth = eachDayOfInterval({
      start: _startDate,
      end: _endDate,
    });
    let freeRows = [];
    if (freeDays) {
      freeRows = Object.keys(freeDays)?.map((userID) => {
        let i = 1;
        const _userID = parseInt(Object.keys(freeDays[userID])[0]);
        let userInUsers = false;
        // check user id in users
        users.forEach((user) => {
          if (user[0] === _userID) userInUsers = true;
        });
        if(userInUsers){

            const newRow = {
                id: users?.filter((userData) => userData[0] === _userID)[0][0],
            };
            newRow.user_id = users?.filter(
                (userData) => userData[0] === _userID
                )[0][1];
                _allDatesInMonth.forEach((date) => {
                    const formattedDate = format(date, "EEE dd MMM");
                    newRow[formattedDate] =
            freeDays[userID][i]?.working_time || freeDays[userID][i]?.break_time
              ? sec2time(freeDays[userID][i]?.working_time) +
              "," +
              sec2time(freeDays[userID][i]?.break_time)
              : "";
              i++;
            });
            return newRow;
        }else{
            return [];
        }
      });
    }

    const _columns = [
      { field: "user_id", headerName: "User", width: 180, sortable: false },

      ..._allDatesInMonth.map((date) => {
        return {
          field: format(date, "EEE dd MMM"),
          headerName:
            windowSize?.width < 2330
              ? format(date, "d ")
              : format(date, "dd MMM"),
          width: windowSize?.width < 2330 ? 50 : 70,
          sortable: false,
        };
      }),
    ];
    const _rows = Object.keys(monthData).map((userID, index) => {
      let i = 1;
      const newRow = { id: users[index][0] };
      newRow.user_id = users[index][1];
      _allDatesInMonth.forEach((date) => {
        const formattedDate = format(date, "EEE dd MMM");
        newRow[formattedDate] =
          monthData[userID][i]?.working_time || monthData[userID][i]?.break_time
            ? sec2time(monthData[userID][i]?.working_time) +
              "," +
              sec2time(monthData[userID][i]?.break_time)
            : "";
        i++;
      });
      return newRow;
    });
    setColumns(_columns);
    setFreeDaysRows(freeRows);
    setRows(_rows);

    // eslint-disable-next-line
  }, [monthData, currentMonth]);
  useEffect(() => {
    getMonthData(
      user?.data.id,
      getMonth(currentMonth) + 1,
      getYear(currentMonth)
    );
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [user]);
  return isLoading ? (
    <Loading />
  ) : (
    <div className="table-container">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        marginY={15}
        className="month-selector-container"
      >
        <Button
          sx={{
            fontWeight: "bold",
          }}
          onClick={handlePrevMonth}
        >
          Prev Month
        </Button>
        <Typography variant="h4">
          {format(currentMonth, "MMMM yyyy")}
        </Typography>
        <Button
          sx={{
            fontWeight: "bold",
          }}
          onClick={handleNextMonth}
        >
          Next Month
        </Button>
      </Grid>
      <Grid display="flex" alignItems={"center"} justifyContent={"flex-end"}>
        <Button
          sx={{
            fontWeight: "bold",
            width: 150,
            my: 2,
          }}
          variant="contained"
          color="secondary"
          onClick={() => setOpenLegend(true)}
        >
          Show Legend
        </Button>
      </Grid>
      <Grid
        display="grid"
        alignItems="center"
        justifyContent="start"
        gridTemplateColumns="repeat(2, 1fr)"
        className="live-clock-container"
      >
        <Button
          sx={{
            fontWeight: "bold",
            width: 150,
            my: 2,
          }}
          variant="contained"
          color="primary"
          onClick={handleRefreshData}
          className="refresh-data-button"
        >
          Refresh Data
        </Button>
        <LiveClock />
      </Grid>

      <div
        style={{
          marginBottom: 50,
          overflowX: windowSize.width > 1760 ? "hidden" : "scroll",
        }}
      >
        <DataGrid
          className={["table-toolbar"]}
          rows={rows.length > 0 ? rows : freeDaysRows}
          columns={columns}
          pageSize={10}
          getRowId={getRowId}
          autoHeight={true}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableVirtualization={true}
          scrollbarSize={12}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 25, 50]}
          onCellClick={handleCellClick}
          sx={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "rgba(10,10,10,.9)",

            borderRadius: 4,
          }}
          getCellClassName={(params) => {
            if (users) {
              const date = parse(params?.field, "EEE dd MMM", currentMonth);

              const dayOfWeek = getDay(date);
              const isSunday = dayOfWeek === 0;
              const isSaturday = dayOfWeek === 6;
              let usersStatus = "";
              let workingHours = 8;
              let freeDaysInterval = 0;
              users.forEach((user) => {
                if (user[0] === params?.id) {
                  usersStatus = user[2];
                  workingHours = user[3];
                }
                for (const interval of freeDays) {
                  const intervalArray = interval[Object.keys(interval)[0]];
                  if (intervalArray.length) {
                    if (intervalArray[0][1] === params?.id) {
                      freeDaysInterval = intervalArray;
                    }
                  }
                }
              });

              if (freeDaysInterval.length) {
                for (const interval of freeDaysInterval) {
                  const startDate = new Date(interval[2]);
                  const endDate = new Date(interval[3]);
                  // Check if the startDate or endDate is "Invalid Date"
                  if (isNaN(startDate) || isNaN(endDate)) {
                    continue; // Skip this iteration
                  }

                  // Ensure startDate is before endDate
                  if (startDate > endDate) {
                    continue; // Skip this iteration
                  }
                  const isFreeDay = isWithinInterval(date, {
                    start: startDate,
                    end: endDate,
                  });

                  if (isFreeDay) {
                    if (isSaturday || isSunday) {
                      return ["grey-background", "cell"];
                    } else {
                      if (interval[5] === "approved") {
                        return ["blue-accepted-background", "cell"];
                      }

                      if (interval[5] === "rejected") {
                        return ["purple-rejected-background ", "cell"];
                      }
                      if (interval[5] === "in_progress") {
                        return ["blue-in-progress-background", "cell"];
                      }
                    }
                  }
                }
              }
              if (
                params.formattedValue &&
                params.formattedValue.includes(",")
              ) {
                const times = params.formattedValue.split(",");

                const _isToday = isToday(date, currentMonth);

                if (_isToday) {
                  switch (usersStatus) {
                    case "clocked_in":
                      return ["green-background", "is-clocked_in "];
                    case "break":
                      return ["yellow-background", "cell"];
                    case "clocked_out":
                      return time2sec(times[0]) >= workingHours * 3600
                        ? ["green-background", "cell"]
                        : ["red-background", "cell"];

                    default:
                      return [""];
                  }
                } else if (
                  time2sec(times[0]) <= workingHours * 3600 &&
                  !_isToday
                ) {
                  return ["red-background", "cell"];
                } else {
                  return ["green-background", "cell"];
                }
              } else {
                const isDateInFuture = isAfter(date, currentMonth);
                if (isSaturday || isSunday) {
                  return ["grey-background", "cell"];
                } else if (isDateInFuture) {
                  return ["cell"];
                }
              }
            }
          }}
        />
      </div>
      <TimeLegendModal open={openLegend} onClose={() => setOpenLegend(false)} />
      <PopUp
        onClose={() => setShowPopUp(false)}
        open={showPopUp}
        data={popUpData}
      />
    </div>
  );
};

export default AdminTM;
