import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmDialog from "./ConfirmDialog";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CloseIcon from "@mui/icons-material/Close";
import api from "./api";
import { API_ENDPOINTS } from "./vars";
import { enqueueSnackbar } from "notistack";
import { useAuth } from "./AuthContext";

const HrHistoryModal = ({ open, onClose, userData, getUsers }) => {
  const { user } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    message: "",
    action: () => {},
  });
  const [userHrHistory, setUserHrHistory] = useState(null);
  const [hourlyRates, setHourlyRates] = useState(null);

  const closeDialog = () => setOpenDialog(false);

  const getHrHistory = async (userID) => {
    try {
      const response = await api.post(API_ENDPOINTS.GET_USER_HR_HISTORY, {
        id: userID,
      });
      if (response.data?.status) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        setUserHrHistory(response.data?.history);
        setHourlyRates(
          response.data?.history?.map((entry) => ({
            id: entry.id,
            hourlyRate: entry.hourlyRate,
          }))
        );
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    }
  };
  const handleRateChange = (id, newRate) => {
    setHourlyRates((prevRates) =>
      prevRates.map((rate) =>
        rate.id === id ? { ...rate, hourlyRate: newRate } : rate
      )
    );
  };

  const isRateChanged = (id, currentRate) => {
    const originalRate = userHrHistory.find(
      (entry) => entry.id === id
    ).hourlyRate;
    return currentRate !== originalRate;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateHourlyRate = async (entryID, newHourlyRate) => {
    try {
      const response = await api.post(API_ENDPOINTS.UPDATE_USER_HR_HISTORY, {
        entryID: entryID,
        newHourlyRate:newHourlyRate
      });
      if (response.data?.status) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    } finally {
      closeDialog();
    }
  };

  const handleUpdateHourlyRate = (entryID, newHourlyRate) => {
    setDialogData({
      message: "Are you sure you want to update user data?",
      action: () => updateHourlyRate(entryID, newHourlyRate),
    });
    setOpenDialog(true);
  };

  useEffect(() => {
    if (userData?.id) {
      getHrHistory(userData?.id);
    }
    return () => getUsers(user?.data.id);
    // eslint-disable-next-line
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign={"center"} fontWeight={600}>
        Hourly rates history
      </DialogTitle>

      <Divider sx={{ px: 30 }} />
      <DialogContent sx={{ gap: 3, display: "flex", flexDirection: "column" }}>
        {userHrHistory?.map((row) => {
          return (
            <Paper
              key={row.id}
              elevation={3}
              style={{
                margin: "10px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: 25,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ whiteSpace: "nowrap", mr: 2 }}
                variant="subtitle1"
                fontWeight={700}
              >
                Period: {formatDate(row.period)}
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 30,
                }}
              >
                <div>
                  <TextField
                    label="Hourly Rate"
                    type="number"
                    value={
                      hourlyRates.find((rate) => rate.id === row.id)
                        ?.hourlyRate || ""
                    }
                    onChange={(e) => handleRateChange(row.id, e.target.value)}
                    fullWidth
                  />
                  <Typography
                    sx={{ whiteSpace: "nowrap", mr: 2 }}
                    variant="subtitle1"
                    fontWeight={500}
                  >
                    Currency: {row?.currency}
                  </Typography>
                </div>
                <Button
                  variant="contained"
                  color="success"
                  disabled={
                    !isRateChanged(
                      row.id,
                      hourlyRates.find((rate) => rate.id === row.id)?.hourlyRate
                    )
                  }
                  sx={{ px: 5 }}
                  startIcon={<SaveAsIcon />}
                  onClick={() =>
                    handleUpdateHourlyRate(
                      row?.id,
                      hourlyRates.find((rate) => rate.id === row.id)
                        ?.hourlyRate || ""
                    )
                  }
                >
                  Update
                </Button>
              </div>
            </Paper>
          );
        })}
      </DialogContent>
      <div
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: "flex-end",
          gap: 20,
        }}
      >
        <Button
          onClick={onClose}
          color="error"
          variant="contained"
          endIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDialog}
        onConfirm={dialogData.action}
        message={dialogData.message}
      />
    </Dialog>
  );
};

export default HrHistoryModal;
