import React, { Fragment, useEffect, useState } from "react";
import {
    Grid,
    Button,
    Typography,
    TextField,
    Paper,
    Container,
    IconButton,
    styled,
    Box,
    CircularProgress,
} from "@mui/material";
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    endOfWeek,
    getMonth,
    getYear,
    getDate,
    isThisMonth,
} from "date-fns";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../utils/AuthContext";
import api from "../utils/api";
import { API_ENDPOINTS } from "../utils/vars";
import { enqueueSnackbar } from "notistack";
import LiveClock from "../utils/LiveClock";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import WeekReport from "../utils/WeekReport";
import ConfirmDialog from "../utils/ConfirmDialog";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const WorkReports = () => {
    const { user } = useAuth();
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [showHistory, setShowHistory] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [dailyReport, setDailyReport] = useState("");
    const [groupedReports, setGroupedReports] = useState({ date: 0 });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        onClose: () =>
            setConfirmDialog((prevValue) => {
                return { ...prevValue, open: false };
            }),
        onConfirm: () => {},
        message: "Are you sure that you want to remove this file?",
    });

    const getFiles = async (
        id,
        week = getWeekKey(),
        hook = setSelectedFiles
    ) => {
        setIsUploading(true);
        try {
            const response = await api.post(API_ENDPOINTS.GET_USER_FILES, {
                user_id: id,
                week: week,
            });
            if (response.data.files) {
                hook(response.data.files);
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: "warning",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        } finally {
            setIsUploading(false);
        }
    };
    const handleFileChange = async (e) => {
        if (user) {
            setIsUploading(true);
            try {
                const formData = new FormData();

                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append("files", e.target.files[i]);
                }
                formData.append("user_id", user.data?.id);
                formData.append("week", getWeekKey());
                const response = await api.post(
                    API_ENDPOINTS.UPLOAD_WEEK_REPORT_FILE,
                    formData,
                    {
                        headers: { "Content-Type": "multipart/form-data" },
                    }
                );
                if (response.data.status) {
                    enqueueSnackbar(response.data?.message, {
                        variant: "success",
                    });
                    if (isThisMonth(currentMonth) && user?.data?.id)
                        getFiles(user.data.id);
                } else {
                    enqueueSnackbar(response.data?.message, {
                        variant: "warning",
                    });
                }
            } catch (error) {
                enqueueSnackbar("Server error, please contact admin!", {
                    variant: "error",
                });
            } finally {
                setIsUploading(false);
            }
        }
    };
    const handleRemoveFile = async (id, fileToRemove) => {
        if (user) {
            setIsUploading(true);
            try {
                const response = await api.post(API_ENDPOINTS.DELETE_FILE, {
                    user_id: id,
                    file_id: fileToRemove?.id,
                });
                if (response.data.status) {
                    enqueueSnackbar(response.data?.message, {
                        variant: "success",
                    });
                    if (isThisMonth(currentMonth) && user?.data?.id)
                        getFiles(user.data.id);
                } else {
                    enqueueSnackbar(response.data?.message, {
                        variant: "warning",
                    });
                }
            } catch (error) {
                enqueueSnackbar("Server error, please contact admin!", {
                    variant: "error",
                });
            } finally {
                setIsUploading(false);
            }
        }
    };
    const downloadFile = async (id, file, isMonthlyReport = 0) => {
        try {
            const response = await api.get(API_ENDPOINTS.DOWNLOAD_FILE, {
                responseType: "blob",
                params: {
                    user_id: id,
                    file_id: file.id,
                    is_monthly_report: isMonthlyReport,
                },
            });
            if (response.data) {
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = file.name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);

                enqueueSnackbar("Download started", { variant: "info" });
            } else {
                enqueueSnackbar("Server error, please contact admin!", {
                    variant: "error",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    const openDialog = (file) => {
        setConfirmDialog((prevValue) => {
            return {
                ...prevValue,
                open: true,
                onConfirm: () => {
                    handleRemoveFile(user?.data?.id, file);
                    prevValue.onClose();
                },
            };
        });
    };
    const handlePrevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const saveReport = async (id, month, year, day) => {
        try {
            const response = await api.post(API_ENDPOINTS.SAVE_REPORT, {
                user_id: id,
                month: month + 1,
                year: year,
                day: day,
                report: dailyReport,
            });
            if (response.data?.status) {
                enqueueSnackbar(response.data?.message, { variant: "success" });

                if (user?.data.id)
                    getReports(
                        user?.data?.id,
                        getMonth(currentMonth),
                        getYear(currentMonth)
                    );
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });

                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        } finally {
        }
    };

    const getReports = async (id, month, year) => {
        try {
            const response = await api.post(API_ENDPOINTS.GET_USER_REPORTS, {
                user_id: id,
                month: month + 1,
                year: year,
            });
            if (response.data?.reports) {
                enqueueSnackbar(response.data?.message, { variant: "success" });

                // Group reports by week
                const _groupedReports = {};
                const reports = response.data?.reports;
                reports.forEach((report) => {
                    const reportDate = new Date(
                        report?.created_at.includes("GMT")
                            ? report?.created_at.slice(0, -3)
                            : report?.created_at
                    );
                    const weekStart = startOfWeek(reportDate, {
                        weekStartsOn: 1,
                    }); // Start week on Monday
                    const weekEnd = endOfWeek(reportDate, { weekStartsOn: 1 });
                    const weekKey = `${format(
                        weekStart,
                        "yyyy-MM-dd"
                    )}/${format(weekEnd, "yyyy-MM-dd")}`;

                    if (!_groupedReports[weekKey]) {
                        _groupedReports[weekKey] = [];
                    }
                    _groupedReports[weekKey].push(report);
                });
                setGroupedReports(_groupedReports);
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });

                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
            console.error(error);
        } finally {
        }
    };

    const getDailyReport = async (id, month, year, day) => {
        try {
            const response = await api.post(API_ENDPOINTS.GET_USER_REPORT, {
                user_id: id,
                month: month + 1,
                year: year,
                day: day,
            });
            if (response.data?.report) {
                enqueueSnackbar(response.data?.message, { variant: "success" });

                setDailyReport(response.data?.report);
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });

                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
            console.error(error);
        } finally {
        }
    };
    const getWeekKey = (date = currentMonth) => {
        const weekStart = startOfWeek(date, {
            weekStartsOn: 1,
        });
        const weekEnd = endOfWeek(date, { weekStartsOn: 6 });
        const weekKey = `${format(weekStart, "yyyy-MM-dd")}/${format(
            weekEnd,
            "yyyy-MM-dd"
        )}`;
        return weekKey;
    };
    const downloadMonthReport = async (id, month, year) => {
        try {
            const response = await api.post(
                API_ENDPOINTS.DOWNLOAD_XLSX_MONTH_REPORT,
                {
                    user_id: id,
                    month: month + 1,
                    year: year,
                }
            );
            if (response.data?.file) {
                enqueueSnackbar(response.data?.message, { variant: "success" });
                const file = response.data?.file;
                console.log(file);
                downloadFile(
                    id,
                    {
                        id: file,
                        name: file.split("/")[1],
                    },
                    1
                );
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });

                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
            console.error(error);
        } finally {
        }
    };
    useEffect(() => {
        if (user?.data?.id)
            getReports(
                user.data.id,
                getMonth(currentMonth),
                getYear(currentMonth)
            );
        if (user?.data?.id)
            getDailyReport(
                user.data.id,
                getMonth(currentMonth),
                getYear(currentMonth),
                getDate(currentMonth)
            );
        if (isThisMonth(currentMonth) && user?.data?.id) getFiles(user.data.id);
        // eslint-disable-next-line
    }, [currentMonth, user]);
    useEffect(() => {
        const handleKeyPress = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === "s") {
                event.preventDefault(); // Prevent the browser's default save behavior

                saveReport(
                    user?.data?.id,
                    getMonth(currentMonth),
                    getYear(currentMonth),
                    getDate(currentMonth)
                );
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
        // eslint-disable-next-line
    }, [dailyReport]);
    return (
        <Container>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                marginY={15}
                className="month-selector-container"
            >
                <Button sx={{ fontWeight: "bold" }} onClick={handlePrevMonth}>
                    Prev Month
                </Button>
                <Typography variant="h4">
                    {format(currentMonth, "MMMM yyyy")}
                </Typography>
                <Button sx={{ fontWeight: "bold" }} onClick={handleNextMonth}>
                    Next Month
                </Button>
            </Grid>
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: 2,
                }}
            >
                <LiveClock />
            </Grid>
            {isThisMonth(currentMonth) && (
                <Paper
                    elevation={3}
                    sx={{
                        padding: 2,
                        marginBottom: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h6" mb={3}>
                        Today's Report
                    </Typography>
                    <TextField
                        label="Write your report here"
                        multiline
                        fullWidth
                        variant="outlined"
                        value={dailyReport}
                        onChange={(ev) => setDailyReport(ev.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                            mt: 3,
                            px: 5,
                            alignSelf: "flex-end",
                        }}
                        endIcon={<SaveIcon />}
                        onClick={() =>
                            saveReport(
                                user?.data?.id,
                                getMonth(currentMonth),
                                getYear(currentMonth),
                                getDate(currentMonth)
                            )
                        }
                    >
                        Save
                    </Button>
                    <Box sx={{ flexDirection: "row", display: "flex" }}>
                        <Box sx={{ flex: 1, display: "grid" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    margin: "32px 0  16px",
                                    gap: 5,
                                }}
                            >
                                <Button
                                    component="label"
                                    variant="contained"
                                    color="success"
                                    startIcon={<CloudUploadIcon />}
                                    sx={{
                                        alignSelf: "flex-end",
                                        justifySelf: "self-start",
                                        px: 5,
                                    }}
                                >
                                    Upload file
                                    <VisuallyHiddenInput
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </Button>
                                {isUploading ? (
                                    <CircularProgress color="info" />
                                ) : (
                                    <Fragment />
                                )}
                            </Box>
                            {selectedFiles.length ? (
                                <Fragment>
                                    <Typography variant="h7">
                                        Selected Files:
                                    </Typography>
                                    {selectedFiles.map((file, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                style={{ flexGrow: 1 }}
                                            >
                                                {file.name} -{" "}
                                                {parseFloat(
                                                    file.size / 1_000_000
                                                ).toFixed(3)}{" "}
                                                MB
                                            </Typography>
                                            <IconButton
                                                color="success"
                                                aria-label="download file"
                                                onClick={() =>
                                                    downloadFile(
                                                        user?.data?.id,
                                                        file
                                                    )
                                                }
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                aria-label="remove file"
                                                onClick={() => openDialog(file)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                </Fragment>
                            ) : (
                                <Fragment />
                            )}
                        </Box>
                    </Box>
                </Paper>
            )}
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                    }}
                >
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <Typography variant="h4" sx={{ mt: 3, mb: 2 }}>
                            History Reports
                        </Typography>
                        <IconButton
                            variant="contained"
                            onClick={() =>
                                setShowHistory((prevValue) => !prevValue)
                            }
                            sx={{
                                height: 30,
                                ml: 1,
                                mb: -1,
                            }}
                        >
                            {Object.keys(groupedReports).length ? (
                                showHistory ? (
                                    <VisibilityIcon />
                                ) : (
                                    <VisibilityOffIcon />
                                )
                            ) : (
                                <></>
                            )}
                        </IconButton>
                    </div>

                    <Button
                        variant="contained"
                        color="info"
                        endIcon={<DownloadIcon />}
                        onClick={() =>
                            downloadMonthReport(
                                user?.data?.id,
                                getMonth(currentMonth),
                                getYear(currentMonth)
                            )
                        }
                        sx={{
                            alignSelf:'center',
                            justifySelf: "self-end",
                            px: 5,
                        }}
                    >
                        Download month report
                    </Button>
                </div>
                <AnimatePresence>
                    {Object.keys(groupedReports).length ? (
                        showHistory && (
                            <motion.div
                                initial={{ opacity: 0, y: 500 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 500 }}
                                transition={{
                                    duration: 0.7,
                                    ease: "easeInOut",
                                }}
                            >
                                {Object.keys(groupedReports).map((weekKey) => {
                                    return (
                                        <WeekReport
                                            key={weekKey}
                                            weekKey={weekKey}
                                            groupedReports={groupedReports}
                                            getReports={getReports}
                                            getDailyReport={getDailyReport}
                                            getFiles={getFiles}
                                            downloadFile={downloadFile}
                                        />
                                    );
                                })}
                            </motion.div>
                        )
                    ) : (
                        <Typography
                            fontSize={24}
                            fontWeight={600}
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            No data here
                        </Typography>
                    )}
                </AnimatePresence>
            </div>
            <ConfirmDialog {...confirmDialog} />
        </Container>
    );
};

export default WorkReports;
