import React, { useEffect, useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import ConfirmDialog from "./ConfirmDialog";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import api from "../utils/api";
import { API_ENDPOINTS, isValidEmail } from "../utils/vars";
import { enqueueSnackbar } from "notistack";
import { useAuth } from "./AuthContext";

const EditUserModal = ({ open, onClose, userData, getUsers }) => {
  const { user } = useAuth();
  const [email, setEmail] = useState(userData?.email);
  const [name, setName] = useState(userData?.name);
  const [position, setPosition] = useState(userData?.position);
  const [role, setRole] = useState("user");
  const [workingHours, setWorkingHours] = useState(userData?.working_hours);
  const [hourlyRate, setHourtlyRate] = useState(userData?.hourly_rate);
  const [currency, setCurrency] = useState(userData?.currency);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    message: "",
    action: () => {},
  });
  const closeDialog = () => setOpenDialog(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  const handleWorkingHours = (event) => {
    setWorkingHours(event.target.value);
  };
  const handleHourlyRate = (event) => {
    setHourtlyRate(event.target.value);
  };
  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };
  const handlePosition = (event) => {
    setPosition(event.target.value);
  };
  const editUserData = async () => {
    try {
      const response = await api.post(API_ENDPOINTS.EDIT_USER, {
        id: userData.id,
        email: email.toLowerCase(),
        name: name,
        role: role,
        position:position,
        working_hours: workingHours,
        hourly_rate: hourlyRate,
        currency: currency,
      });
      if (response.data?.status) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        getUsers(user?.data.id);
        setEmail("");
        setName("");
        setPosition("");
        setRole("user");
        setWorkingHours(8);
        setHourtlyRate(0);
        setCurrency("RON");
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    } finally {
      closeDialog();
      onClose();
    }
  };
  const handleSubmit = () => {
    if (!email.trim() || !isValidEmail(email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "warning",
      });
      return;
    }
    if (!name.trim()) {
      enqueueSnackbar("Please enter a valid name.", {
        variant: "warning",
      });
      return;
    }
    if (!role.trim()) {
      enqueueSnackbar("Please select a role.", {
        variant: "warning",
      });
      return;
    }
    if (!position.trim()) {
      enqueueSnackbar("Please enter a valid contractual position.", {
        variant: "warning",
      });
      return;
    }
    if (!(workingHours >= 1) && !(workingHours <= 12)) {
      enqueueSnackbar("Working hours should be between 1 and 12.", {
        variant: "warning",
      });
      return;
    }
    if (hourlyRate <= 0) {
      enqueueSnackbar("Hourly rate should be bigger than 0.", {
        variant: "warning",
      });
      return;
    }
    if (currency.length > 3) {
      enqueueSnackbar("Currency should be a 3-char maximum text.", {
        variant: "warning",
      });
      return;
    }
    setDialogData({
      message: "Are you sure you want to update user data?",
      action: editUserData,
    });
    setOpenDialog(true);
  };

  useEffect(() => {
    setEmail(userData?.email);
    setName(userData?.name);
    setRole(userData?.role);
    setPosition(userData?.position);
    setWorkingHours(userData?.working_hours);
    setCurrency(userData?.currency);
    setHourtlyRate(userData?.hourly_rate);

    return () => {
      setEmail("");
      setName("");
      setRole("");
      setPosition("");
      setWorkingHours(8);
      setHourtlyRate(0);
      setCurrency("RON");
    };
    // eslint-disable-next-line
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign={"center"} fontWeight={600}>
        Edit User Data
      </DialogTitle>

      <Divider sx={{ px: 30 }} />
      <DialogContent sx={{ gap: 3, display: "flex", flexDirection: "column" }}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          label="Contractual position"
          variant="outlined"
          fullWidth
          value={position}
          onChange={handlePosition}
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={handleRoleChange} label="Role">
            <MenuItem value="user">User</MenuItem>
            {["admin", "dev"].includes(user?.data.role)
              ? [
                  <MenuItem key={1} value="admin">Admin</MenuItem>,
                  <MenuItem key={2} value="supervisor">Supervisor</MenuItem>,
                  <MenuItem key={3} value="dev">Developer</MenuItem>,
                ]
              : null}
          </Select>
        </FormControl>
        <TextField
          label="Working Hours"
          variant="outlined"
          type="number"
          fullWidth
          value={workingHours}
          onChange={handleWorkingHours}
        />
        <TextField
          label="Hourly Rate"
          variant="outlined"
          type="number"
          fullWidth
          value={hourlyRate}
          onChange={handleHourlyRate}
        />
        <Typography maxWidth="md" sx={{ my:0, fontSize:13, fontWeight:700, color:'red' }}>
          *** The new hourly rate will take effect in the next month!
        </Typography>
        <TextField
          label="Currency"
          variant="outlined"
          type="string"
          fullWidth
          value={currency}
          onChange={handleCurrency}
        />
      </DialogContent>
      <div
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: "flex-end",
          gap: 20,
        }}
      >
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
          color="error"
          variant="contained"
          endIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDialog}
        onConfirm={dialogData.action}
        message={dialogData.message}
      />
    </Dialog>
  );
};

export default EditUserModal;