import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAuth } from "./AuthContext";
import { enqueueSnackbar } from "notistack";
import api from "./api";
import { API_ENDPOINTS } from "./vars";

function RequestFreeDaysModal({ open, handleClose }) {
    const { user } = useAuth();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleSubmit = async (id) => {
        try {
            const response = await api.post(API_ENDPOINTS.REQUEST_FREE_DAYS, {
              startDate,
              endDate,
              id
            });
            if(response.data?.status){
                enqueueSnackbar(response.data?.message, { variant: "success" });
            }else{
                
                enqueueSnackbar(response.data?.message, { variant: "info" });
            }
          } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
          }finally{

              handleClose();
            }
    };
    
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    p: 4,
                    bgcolor: "background.paper",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,

                    borderRadius: 3,
                }}
            >
                <Typography
                    fontWeight={700}
                    fontSize={24}
                    px={2}
                    py={2}
                    textAlign={"center"}
                >
                    Select an interval for your free days:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={setStartDate}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </LocalizationProvider>
                <Button
                    onClick={()=>handleSubmit(user?.data.id)}
                    variant="contained"
                    sx={{ mx: 15 }}
                >
                    Submit Request
                </Button>
            </Box>
        </Modal>
    );
}

export default RequestFreeDaysModal;
