import React, { Fragment, useEffect, useState } from "react";
import api from "../../utils/api";
import { API_ENDPOINTS } from "../../utils/vars";
import { useAuth } from "../../utils/AuthContext";
import { enqueueSnackbar } from "notistack";
import {
    Container,
    Typography,
    Grid,
    Paper,
    Button,
    Box,
    Divider,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ConfirmDialog from "../../utils/ConfirmDialog";
import { differenceInMilliseconds, format } from "date-fns";

const AdminSR = () => {
    const { user } = useAuth();
    const [reports, setReports] = useState([]);
    const [freeDays, setFreeDays] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({
        onConfirm: () => {},
        message: "",
    });
    const getReports = async (id) => {
        try {
            const response = await api.post(
                API_ENDPOINTS.GET_ALL_USERS_REQUESTS_REPORT,
                {
                    user_id: id,
                }
            );
            if (response.data.requests) {
                enqueueSnackbar(response.data?.message, {
                    variant: "success",
                });
                setReports(response.data.requests);
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: "warning",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    const getFreeDaysRequests = async (id) => {
        try {
            const response = await api.post(
                API_ENDPOINTS.GET_ALL_FREE_DAYS_REQUESTS,
                {
                    user_id: id,
                }
            );
            if (response.data.free_days) {
                enqueueSnackbar(response.data?.message, {
                    variant: "success",
                });
                setFreeDays(response.data.free_days);
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: "warning",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    const handleConfirm = async (id, item, status) => {
        try {
            const response = await api.post(
                API_ENDPOINTS.SUBMIT_REQUEST_RESPONSE,
                {
                    user_id: id,
                    req_id: item.id,
                    req_response: status,
                }
            );
            if (response.data.status) {
                enqueueSnackbar(response.data?.message, {
                    variant: "success",
                });
                getReports(user.data.id);
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: "warning",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        } finally {
            setShowConfirmDialog(false);
        }
    };
    const handleConfirmFreeDays = async (id, item, status) => {
        try {
            const response = await api.post(
                API_ENDPOINTS.SUBMIT_FREE_DAYS_REQUEST_RESPONSE,
                {
                    user_id: id,
                    req_id: item.id,
                    req_response: status,
                }
            );
            if (response.data.status) {
                enqueueSnackbar(response.data?.message, {
                    variant: "success",
                });
                getReports(user.data.id);
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: "warning",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        } finally {
            setShowConfirmDialog(false);
        }
    };
    const handleApprove = (id, item, forFreeDays = false) => {
        setShowConfirmDialog(true);
        setConfirmationDialog({
            onConfirm: () =>
                forFreeDays
                    ? handleConfirmFreeDays(id, item, "approved")
                    : handleConfirm(id, item, "approved"),
            message: "Are you sure that you want to approve this request?",
        });
    };
    const handleReject = (id, item, forFreeDays = false) => {
        setShowConfirmDialog(true);
        setConfirmationDialog({
            onConfirm: () =>
                forFreeDays
                    ? handleConfirmFreeDays(id, item, "rejected")
                    : handleConfirm(id, item, "rejected"),
            message: "Are you sure that you want to reject this request?",
        });
    };
    function formatTimeDifference(ms) {
        const hours = Math.floor(Math.abs(ms) / 3600000);
        const minutes = Math.floor((Math.abs(ms) % 3600000) / 60000);
        if (hours > 0 && minutes > 0) {
            return `${Math.abs(hours)}h ${Math.abs(minutes)}m`;
        } else if (hours > 0) {
            return `${Math.abs(hours)}h`;
        } else if (hours < 0 && minutes === 0) {
            return `${Math.abs(hours)}h ${Math.abs(minutes)}m`;
        } else {
            return `${Math.abs(minutes)}m`;
        }
    }
    useEffect(() => {
        if (user?.data.id) {
            getReports(user.data.id);
            getFreeDaysRequests(user.data.id);
        }
        // eslint-disable-next-line
    }, []);
    return (
        <Container sx={{ my: 15 }}>
            <Grid container spacing={3} flexDirection={"column"}>
                {freeDays.map((item) => (
                    <Grid item xs={12} key={item.id}>
                        <Paper
                            elevation={5}
                            sx={{
                                borderRadius: 4,
                                flex: 1,
                            }}
                        >
                            <Paper
                                elevation={5}
                                sx={{
                                    py: 2,
                                    px: 3,
                                    color:
                                        item.status === "rejected"
                                            ? "white"
                                            : "",
                                    borderRadius:
                                        item.status === "in_progress" ? 0 : 4,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 16,
                                }}
                                className={
                                    item.status === "approved"
                                        ? "green-background"
                                        : item.status === "in_progress"
                                        ? "orange-background"
                                        : "red-background "
                                }
                            >
                                <Typography
                                    variant="h6"
                                    fontWeight={700}
                                    component="div"
                                >
                                    Free days request
                                </Typography>
                                {item.status === "in_progress" ? (
                                    <Divider />
                                ) : (
                                    <Fragment />
                                )}
                                <Typography
                                    variant="h6"
                                    fontWeight={500}
                                    component="div"
                                    sx={{
                                        "::first-letter": {
                                            textTransform: "capitalize",
                                        },
                                    }}
                                >
                                    {item.status.includes("_")
                                        ? item.status.replace("_", " ")
                                        : item.status}{" "}
                                    {item.status !== "in_progress"
                                        ? " by " + item?.reviewed_by
                                        : ""}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    sx={{
                                        color:
                                            item.status === "rejected"
                                                ? "white"
                                                : "",
                                    }}
                                >
                                    Requested by: {item.user_name}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    sx={{
                                        color:
                                            item.status === "rejected"
                                                ? "white"
                                                : "",
                                    }}
                                >
                                    Requested on:{" "}
                                    {format(
                                        new Date(item.created_at),
                                        "yyy.MM.dd "
                                    )}
                                    at{" "}
                                    {format(
                                        new Date(item.created_at),
                                        "hh:mm aaa"
                                    )}
                                </Typography>

                                <Typography mt={3}>
                                    {item.user_name.split(" ")[0]} requested
                                    free days between starting with
                                    {"  "}
                                    {format(
                                        new Date(item.start_date),
                                        "yyy.MM.dd "
                                    )}
                                    until{" "}
                                    {format(
                                        new Date(item.end_date),
                                        "yyy.MM.dd "
                                    )}
                                    .
                                    {/* to add this you have to add an array with free days from the state and also create a function that subtract weekends  */}
                                    {/* (
                                    {differenceInDays(
                                        new Date(item.end_date),
                                        new Date(item.start_date)
                                    )}{" "}
                                    free days) */}
                                </Typography>
                            </Paper>
                            {item.status === "in_progress" ? (
                                <Box
                                    sx={{
                                        py: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        gap: 3,
                                        pr: 3,
                                    }}
                                >
                                    <Button
                                        color="success"
                                        variant="contained"
                                        startIcon={<ThumbUpIcon />}
                                        onClick={() =>
                                            handleApprove(
                                                user.data?.id,
                                                item,
                                                true
                                            )
                                        }
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        endIcon={<ThumbDownIcon />}
                                        onClick={() =>
                                            handleReject(
                                                user.data?.id,
                                                item,
                                                true
                                            )
                                        }
                                    >
                                        Reject
                                    </Button>
                                </Box>
                            ) : (
                                <Fragment />
                            )}
                        </Paper>
                    </Grid>
                ))}
                {reports.map((item) => (
                    <Grid item xs={12} key={item.id}>
                        <Paper
                            elevation={5}
                            sx={{
                                borderRadius: 4,
                                flex: 1,
                            }}
                        >
                            <Paper
                                elevation={5}
                                sx={{
                                    py: 2,
                                    px: 3,
                                    color:
                                        item.status === "rejected"
                                            ? "white"
                                            : "",
                                    borderRadius:
                                        item.status === "in_progress" ? 0 : 4,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 16,
                                }}
                                className={
                                    item.status === "approved"
                                        ? "green-background"
                                        : item.status === "in_progress"
                                        ? "orange-background"
                                        : "red-background "
                                }
                            >
                                <Typography
                                    variant="h6"
                                    fontWeight={700}
                                    component="div"
                                >
                                    Reason: {item.reason}
                                </Typography>
                                {item.status === "in_progress" ? (
                                    <Divider />
                                ) : (
                                    <Fragment />
                                )}
                                <Typography
                                    variant="h6"
                                    fontWeight={500}
                                    component="div"
                                    sx={{
                                        "::first-letter": {
                                            textTransform: "capitalize",
                                        },
                                    }}
                                >
                                    {item.status.includes("_")
                                        ? item.status.replace("_", " ")
                                        : item.status}{" "}
                                    {item.status !== "in_progress"
                                        ? " by " + item.update_by_name
                                        : ""}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    sx={{
                                        color:
                                            item.status === "rejected"
                                                ? "white"
                                                : "",
                                    }}
                                >
                                    Requested by: {item.user_name}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    sx={{
                                        color:
                                            item.status === "rejected"
                                                ? "white"
                                                : "",
                                    }}
                                >
                                    Requested on:{" "}
                                    {format(
                                        new Date(item.req_created_at),
                                        "yyy.MM.dd "
                                    )}
                                    at{" "}
                                    {format(
                                        new Date(item.req_created_at),
                                        "hh:mm aaa"
                                    )}
                                </Typography>

                                <Typography mt={3}>
                                    {item.user_name.split(" ")[0]} wants to
                                    change the{" "}
                                    {item.change_status.includes("_")
                                        ? item.change_status.replace("_", " ")
                                        : item.change_status}{" "}
                                    from{" "}
                                    {format(
                                        new Date(
                                            item.original_timestamp.includes(
                                                "GMT"
                                            )
                                                ? item.original_timestamp.slice(
                                                      0,
                                                      -3
                                                  )
                                                : item.original_timestamp
                                        ),
                                        "yyy.MM.dd "
                                    )}
                                    at{" "}
                                    {format(
                                        new Date(
                                            item.original_timestamp.includes(
                                                "GMT"
                                            )
                                                ? item.original_timestamp.slice(
                                                      0,
                                                      -3
                                                  )
                                                : item.original_timestamp
                                        ),
                                        "hh:mm aaa"
                                    )}{" "}
                                    to{" "}
                                    {format(
                                        new Date(
                                            item.req_requested_timestamp.includes(
                                                "GMT"
                                            )
                                                ? item.req_requested_timestamp.slice(
                                                      0,
                                                      -3
                                                  )
                                                : item.req_requested_timestamp
                                        ),
                                        "hh:mm aaa"
                                    )}
                                    . (
                                    {formatTimeDifference(
                                        differenceInMilliseconds(
                                            new Date(
                                                item.req_requested_timestamp.includes(
                                                    "GMT"
                                                )
                                                    ? item.req_requested_timestamp.slice(
                                                          0,
                                                          -3
                                                      )
                                                    : item.req_requested_timestamp
                                            ),
                                            new Date(
                                                item.original_timestamp.includes(
                                                    "GMT"
                                                )
                                                    ? item.original_timestamp.slice(
                                                          0,
                                                          -3
                                                      )
                                                    : item.original_timestamp
                                            )
                                        )
                                    )}{" "}
                                    {new Date(
                                        item.original_timestamp.includes("GMT")
                                            ? item.original_timestamp.slice(
                                                  0,
                                                  -3
                                              )
                                            : item.original_timestamp
                                    ) >
                                    new Date(
                                        item.req_requested_timestamp.includes(
                                            "GMT"
                                        )
                                            ? item.req_requested_timestamp.slice(
                                                  0,
                                                  -3
                                              )
                                            : item.req_requested_timestamp
                                    )
                                        ? "less"
                                        : "more"}
                                    )
                                </Typography>
                            </Paper>
                            {item.status === "in_progress" ? (
                                <Box
                                    sx={{
                                        py: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        gap: 3,
                                        pr: 3,
                                    }}
                                >
                                    <Button
                                        color="success"
                                        variant="contained"
                                        startIcon={<ThumbUpIcon />}
                                        onClick={() =>
                                            handleApprove(user.data?.id, item)
                                        }
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        endIcon={<ThumbDownIcon />}
                                        onClick={() =>
                                            handleReject(user.data?.id, item)
                                        }
                                    >
                                        Reject
                                    </Button>
                                </Box>
                            ) : (
                                <Fragment />
                            )}
                        </Paper>
                    </Grid>
                ))}
                {reports.length === 0 && (
                    <Typography
                        variant="h5"
                        fontWeight={500}
                        textAlign={"center"}
                        my={15}
                    >
                        No reports for the moment.
                    </Typography>
                )}
            </Grid>
            <ConfirmDialog
                {...confirmationDialog}
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
            />
        </Container>
    );
};

export default AdminSR;
