import React from "react";
import Clock from "react-live-clock";

const LiveClock = () => {
    return (
        <Clock
            className="live-clock"
            format={"dddd, MMMM Do YYYY, h:mm:ss A"}
            ticking={true}
            blinking={true}
            style={{
                justifySelf: "self-end",
            }}
        />
    );
};

export default LiveClock;
