import React, {  useState } from "react";
import { Typography, IconButton } from "@mui/material";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import CustomModal from "./CustomModal";
import { enqueueSnackbar } from "notistack";
const Report = ({
    report,
    isCurrentWeek,
    getReports,
    getDailyReport,
    _for="user"
}) => {
    const [open, setOpen] = useState(false);

    const toggleEdit = () => {
        setOpen((prevValue) => !prevValue);
    };
    return (
        <div elevation={3} sx={{ padding: 2, marginBottom: 1 }}>
            <Typography fontWeight={600}>
                {format(new Date(report?.created_at.includes("GMT") ? report?.created_at.slice(0, -3) : report?.created_at), "MMMM dd")}
            </Typography>

            <div
                key="view"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{
                    duration: 0.7,
                    ease: "easeInOut",
                    delayChildren: 1,
                }}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <p
                    className="report-text"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    {report?.report}
                </p>
                {(isCurrentWeek && _for !== "admin") && (
                    <IconButton
                        onClick={toggleEdit}
                        sx={{
                            height: 40,
                            alignSelf: "flex-end",
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                )}
            </div>
            <CustomModal
                open={open}
                onClose={toggleEdit}
                getReports={getReports}
                getDailyReport={getDailyReport}
                enqueueSnackbar={enqueueSnackbar}
                report={report}
            />
        </div>
    );
};

export default Report;
