import React, { useState } from "react";
import { Button, Container, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import api from "../utils/api";
import { API_ENDPOINTS } from "../utils/vars";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const { token } = useParams();
    
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [newPassword, setNewPassword] = useState("");

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleSubmit = async () => {
        if (!newPassword.trim()) {
            enqueueSnackbar("Please fill in with the new password!", {
                variant: "error",
            });
            return;
        }
        try {
            const response = await api.post(API_ENDPOINTS.RESET_PASSWORD, {
                token: token,
                password:newPassword
            });
            if (response.data?.status) {
                enqueueSnackbar("Password reset with success", {
                    variant: "success",
                });
                setNewPassword("")
                setTimeout(() => {
                    navigate('/')
                }, 1500);
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "warning" });
                return;
            }
        } catch (error) {
            console.error(error)
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    
    return (
        <Container maxWidth="sm" sx={{ marginY: 15 }}>
            <Typography py={3} variant="h4">
                Reset Password
            </Typography>
            <TextField
                type="password"
                label="New Password"
                fullWidth
                required
                value={newPassword}
                onChange={handlePasswordChange}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3 }}
                onClick={handleSubmit}
            >
                Reset Password
            </Button>
        </Container>
    );
};

export default ResetPassword;
