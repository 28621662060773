import React, { Fragment, useEffect, useState } from "react";
import {
    Typography,
    Paper,
    Container,
    Avatar,
    Box,
    Button,
    Grid
} from "@mui/material";
import { motion } from "framer-motion";
import { useAuth } from "../../utils/AuthContext";
import api from "../../utils/api";
import { API_ENDPOINTS, stringAvatar } from "../../utils/vars";
import {  enqueueSnackbar } from "notistack";
import RateReviewIcon from '@mui/icons-material/RateReview';
import UserReports from "../../utils/UserReports";
import LiveClock from "../../utils/LiveClock";
const AdminWR = () => {
    const { user } = useAuth();
    const [users, setUsers] = useState([])
    const [open, setOpen] = useState(false)
    const [userData, setUserData] = useState({id:0,name:""})
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(()=>{

        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    },[])
    


    const getUsers = async (id) => {
        try {
            const response = await api.post(API_ENDPOINTS.GET_USERS, {
                user_id: id,
                used_at:'wr'
            });
            if (response.data?.users) {
                enqueueSnackbar(response.data?.message, { variant: "success" });
                setUsers(response.data?.users)
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });
                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    const handleViewReport = (user) => {
        setUserData(user)
        setOpen(true)
    }
    useEffect(() => {
        if (user?.data.id)
            getUsers(
                user?.data?.id
            );
       
        // eslint-disable-next-line
    }, [ user]);
   
    return (
        <Fragment>
            <Container sx={{my:15}}>
            <Grid sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    marginBottom:2
                }}>
            <LiveClock />
              </Grid>
                <div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h4" sx={{ mt: 3, mb: 2 }}>
                            Users:
                        </Typography>
                        
                    </div>
                            <motion.div
                                initial={{ opacity: 0, y: 500 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 500 }}
                                transition={{
                                    duration: 0.7,
                                    ease: "easeInOut",
                                }}
                            >
                                {users.map((user) => {
                                        return (
                                            <Paper
                                                key={user.id}
                                                elevation={4}
                                                sx={{
                                                    padding: 2,
                                                    marginBottom: 3,
                                                    borderRadius:4,
                                                    display:'flex',
                                                    justifyContent:'space-between',
                                                    alignItems:'center'
                                                }}
                                            >
                                                <Box sx={{
                                                    display:'flex',
                                                    alignItems:'flex-end',
                                                    gap:1

                                                }}>

                                                <Avatar {...stringAvatar(user?.name)} />
                                                <Typography fontSize={windowSize.width > 960 ? 18 : 14} fontWeight={600}>{user.name}</Typography>
                                                </Box>
                                                <Box >
                                                <Button
                                                    variant="contained"
                                                    color="info"
                                                    sx={{
                                                        px: windowSize.width > 960 ?  5 : 1,
                                                        alignSelf:'flex-end'
                                                    }}
                                                    endIcon={<RateReviewIcon />}
                                                    onClick={()=>handleViewReport(user)}
                                                >
                                                    {windowSize.width > 960 ? "View report" : "Open"}
                                                </Button>
                                                </Box>
                                            </Paper>
                                        );
                                    })}
                            </motion.div>
                        
                </div>
            </Container>
            <UserReports open={open} _for={"admin"} onClose={()=>setOpen(false)} userID={userData.id} userName={userData.name}/>
            </Fragment>
    );
};

export default AdminWR;
