import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    Box,
    Typography,
    Grid,
    Container,
    IconButton,
} from "@mui/material";
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    endOfWeek,
    getMonth,
    getYear,
} from "date-fns";
import api from "../utils/api";
import { API_ENDPOINTS } from "../utils/vars";
import CancelIcon from "@mui/icons-material/Cancel";
import { enqueueSnackbar } from "notistack";
import WeekReport from "./WeekReport";
import DownloadIcon from "@mui/icons-material/Download";
function UserReports({ open, onClose, userID, userName, _for }) {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [groupedReports, setGroupedReports] = useState({});
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, []);
    const handlePrevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };
    const getFiles = async (id, week, hook) => {
        try {
            const response = await api.post(API_ENDPOINTS.GET_USER_FILES, {
                user_id: id,
                week: week,
            });
            if (response.data.files) {
                hook(response.data.files);
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: "warning",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    const downloadFile = async (id, file, isMonthlyReport = 0) => {
        try {
            const response = await api.get(API_ENDPOINTS.DOWNLOAD_FILE, {
                responseType: "blob",
                params: {
                    user_id: id,
                    file_id: file.id,
                    is_monthly_report: isMonthlyReport,
                },
            });
            if (response.data) {
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = file.name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);

                enqueueSnackbar("Download started", { variant: "info" });
            } else {
                enqueueSnackbar("Server error, please contact admin!", {
                    variant: "error",
                });
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    const handleNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };
    const getReports = async (id, month, year) => {
        try {
            const response = await api.post(API_ENDPOINTS.GET_USER_REPORTS, {
                user_id: id,
                month: month + 1,
                year: year,
            });
            if (response.data?.reports) {
                enqueueSnackbar(response.data?.message, { variant: "success" });

                // Group reports by week
                const _groupedReports = {};
                const reports = response.data?.reports;
                reports.forEach((report) => {
                    const reportDate = new Date(
                        report?.created_at.includes("GMT")
                            ? report?.created_at.slice(0, -3)
                            : report?.created_at
                    );
                    const weekStart = startOfWeek(reportDate, {
                        weekStartsOn: 1,
                    }); // Start week on Monday
                    const weekEnd = endOfWeek(reportDate, { weekStartsOn: 1 });
                    const weekKey = `${format(
                        weekStart,
                        "yyyy-MM-dd"
                    )}/${format(weekEnd, "yyyy-MM-dd")}`;

                    if (!_groupedReports[weekKey]) {
                        _groupedReports[weekKey] = [];
                    }
                    _groupedReports[weekKey].push(report);
                });
                setGroupedReports(_groupedReports);
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });

                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
            console.error(error);
        } finally {
        }
    };
    const downloadMonthReport = async (id, month, year) => {
        try {
            const response = await api.post(
                API_ENDPOINTS.DOWNLOAD_XLSX_MONTH_REPORT,
                {
                    user_id: id,
                    month: month + 1,
                    year: year,
                }
            );
            if (response.data?.file) {
                enqueueSnackbar(response.data?.message, { variant: "success" });
                const file = response.data?.file;
                console.log(file);
                downloadFile(
                    id,
                    {
                        id: file,
                        name: file.split("/")[1],
                    },
                    1
                );
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });

                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
            console.error(error);
        } finally {
        }
    };
    useEffect(() => {
        if (open) {
            getReports(userID, getMonth(currentMonth), getYear(currentMonth));
        }
    }, [open, currentMonth, userID]);
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
                setCurrentMonth(new Date());
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    bgcolor: "white",
                    boxShadow: 24,
                    p: "10px 20px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: 3,
                    width: windowSize.width > 960 ? "50vw" : "95vw",
                    height: windowSize.width > 960 ? "auto" : "80vh",
                    overflow: "scroll",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        fontWeight={700}
                        fontSize={24}
                        px={2}
                        py={2}
                        textAlign={"center"}
                    >
                        {userName.split(" ")[0]}'s reports
                    </Typography>

                    <IconButton
                        sx={{
                            height: 55,
                            width: 55,
                        }}
                        onClick={() => {
                            onClose();
                            setCurrentMonth(new Date());
                        }}
                    >
                        <CancelIcon
                            style={{
                                height: 40,
                                width: 40,
                                color: "red",
                            }}
                        />
                    </IconButton>
                </Box>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        px: windowSize.width > 960 ? 5 : 1,
                        alignSelf: "flex-end",
                    }}
                    endIcon={<DownloadIcon />}
                    onClick={() => downloadMonthReport(userID, getMonth(currentMonth), getYear(currentMonth))}
                >
                    Download month report
                </Button>
                <Container sx={{ width: "100%" }}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        marginY={15}
                        className="month-selector-container"
                    >
                        <Button
                            sx={{ fontWeight: "bold" }}
                            onClick={handlePrevMonth}
                        >
                            Prev Month
                        </Button>
                        <Typography variant="h4">
                            {format(currentMonth, "MMMM yyyy")}
                        </Typography>
                        <Button
                            sx={{ fontWeight: "bold" }}
                            onClick={handleNextMonth}
                        >
                            Next Month
                        </Button>
                    </Grid>
                    <Typography variant="h4" sx={{ mt: 3, mb: 2 }}>
                        Reports
                    </Typography>
                    <div
                        style={{
                            display: "block",
                            overflow: "scroll",
                            maxHeight: "40vh",
                        }}
                    >
                        {Object.keys(groupedReports).length ? (
                            <div>
                                {Object.keys(groupedReports).map((weekKey) => {
                                    return (
                                        <WeekReport
                                            key={weekKey}
                                            weekKey={weekKey}
                                            groupedReports={groupedReports}
                                            getReports={getReports}
                                            getDailyReport={() => {}}
                                            getFiles={getFiles}
                                            downloadFile={downloadFile}
                                            _for={_for}
                                            userID={userID}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <Typography
                                fontSize={24}
                                fontWeight={600}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                No data here
                            </Typography>
                        )}
                    </div>
                </Container>
            </Box>
        </Modal>
    );
}

export default UserReports;
