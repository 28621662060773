import React from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    IconButton,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuth } from "./AuthContext";
const AttachedFilesModal = ({ open, onClose, files, downloadFile }) => {
    const { user } = useAuth();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Attached Files</DialogTitle>
            <Divider/>
            <DialogContent>
                {files.map((file, index) => (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1" sx={{ flexGrow: 1, pr:10 }}>
                            {file.name} -{" "}
                            {parseFloat(file.size / 1_000_000).toFixed(3)} MB
                        </Typography>
                        <IconButton
                            color="success"
                            aria-label="download file"
                            onClick={() => downloadFile(user?.data?.id, file)}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </div>
                ))}
            </DialogContent>
            <div
                style={{
                    padding: "16px",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 20,
                }}
            >
                <Button
                    onClick={onClose}
                    color="error"
                    variant="contained"
                    endIcon={<CloseIcon />}
                >
                    Cancel
                </Button>
            </div>
        </Dialog>
    );
};

export default AttachedFilesModal;
