import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button } from "@mui/material";
import Report from "./Report";
import { format, isSameWeek } from "date-fns";
import { useAuth } from "./AuthContext";
import AttachedFilesModal from "./AttachedFilesModal";

const WeekReport = ({
    groupedReports,
    getReports,
    getDailyReport,
    getFiles,
    weekKey,
    downloadFile,
    userID,
    _for = "user",
}) => {
    const { user } = useAuth();
    const [files, setFiles] = useState([]);
    const [showFiles, setShowFiles] = useState(false);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    const weekReports = groupedReports[weekKey];
    const isCurrentWeek = isSameWeek(
        new Date(weekReports[0].created_at),
        new Date()
    );
    const [startDate, endDate] = weekKey.split("/");

    const handleShowFiles = () => {
        setShowFiles(true);
    };

    useEffect(() => {
        if (user?.data?.id && _for === "user" ) {
            getFiles(user.data.id, weekKey, setFiles);
        }
        else if ( _for === "admin"){
            getFiles(userID, weekKey, setFiles);
        }
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            setFiles([]);
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line
    }, [groupedReports, user]);
    return (
        <Paper
            elevation={4}
            sx={{
                padding: 2,
                marginBottom: 3,
                mt: _for === "admin" ? 3 : "auto",
                mx: _for === "admin" ? 3 : "auto",
            }}
        >
            <Box
                display={"flex"}
                my={1}
                flexDirection={windowSize.width > 960 ? "row": "column"}
                gap={2}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="h6" fontWeight={700}>
                    Week between{" "}
                    {format(new Date(startDate), "MMM") !==
                    format(new Date(endDate), "MMM")
                        ? format(new Date(startDate), "dd MMM") +
                          " - " +
                          format(new Date(endDate), "dd MMM")
                        : format(new Date(startDate), "dd") +
                          " - " +
                          format(new Date(endDate), "dd MMM")}
                </Typography>
                {files.length > 0 && (
                    <Button
                        sx={{
                            fontWeight: "bold",
                            alignSelf: windowSize.width > 960 ? "center": 'flex-end'
                        }}
                        variant="contained"
                        color="info"
                        onClick={handleShowFiles}
                    >
                        Attached files
                    </Button>
                )}
            </Box>
            <div
                style={{
                    backgroundColor: "grey",
                    height: "1px",
                    marginBottom: 20,
                }}
            ></div>
            {weekReports.map((report) => (
                <Report
                    key={report?.id}
                    report={report}
                    isCurrentWeek={isCurrentWeek}
                    getReports={getReports}
                    getDailyReport={getDailyReport}
                    _for={_for}
                />
            ))}
            <AttachedFilesModal
                open={showFiles}
                onClose={() => setShowFiles(false)}
                files={files}
                downloadFile={downloadFile}
            />
        </Paper>
    );
};

export default WeekReport;
