import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    Divider,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from "@mui/icons-material/Close";
import api from "../utils/api";
import { API_ENDPOINTS, isValidEmail } from "../utils/vars";
import { enqueueSnackbar } from "notistack";

const LostPasswordModal = ({ open, onClose }) => {
    const [email, setEmail] = useState("");
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleSubmit = async () => {
        if (!email.trim() && isValidEmail(email)) {
            enqueueSnackbar("Please insert a valid email!", {
                variant: "error",
            });
            return;
        }
        try {
            const response = await api.post(
                API_ENDPOINTS.REQUEST_NEW_PASSWORD,
                {
                    email: email,
                }
            );
            if (response.data?.status) {
                enqueueSnackbar(
                    "You will receive a email with all the details.",
                    {
                        variant: "success",
                    }
                );
                setEmail("");
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "warning" });
                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        }
    };
    useEffect(() => {
        return () => setEmail("");
    }, [open]);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle textAlign={"center"} fontWeight={600}>
                Reset Password:
            </DialogTitle>
            <Divider sx={{ px: 30 }} />
            <DialogContent
                sx={{ gap: 3, display: "flex", flexDirection: "column" }}
            >
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                />
            </DialogContent>
            <div
                style={{
                    padding: "16px",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 20,
                }}
            >
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    startIcon={<SendIcon />}
                >
                    Send email
                </Button>
                <Button
                    onClick={onClose}
                    color="error"
                    variant="contained"
                    endIcon={<CloseIcon />}
                >
                    Cancel
                </Button>
            </div>
        </Dialog>
    );
};

export default LostPasswordModal;
