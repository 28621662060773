import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "./ConfirmDialog";
import api from "../utils/api";
import { API_ENDPOINTS, isValidEmail } from "../utils/vars";
import { enqueueSnackbar } from "notistack";
import { useAuth } from "./AuthContext";

const CreateAccountModal = ({ open, onClose, getUsers }) => {
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("user");
  const [workingHours, setWorkingHours] = useState(8);
  const [hourlyRate, setHourtlyRate] = useState(0);
  const [currency, setCurrency] = useState("RON");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    message: "",
    action: () => {},
  });

  const closeDialog = () => setOpenDialog(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  const handleWorkingHours = (event) => {
    setWorkingHours(event.target.value);
  };
  const handleHourlyRate = (event) => {
    setHourtlyRate(event.target.value);
  };
  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };
  const handlePosition = (event) => {
    setPosition(event.target.value);
  };
  const createNewAccount = async () => {
    try {
      const response = await api.post(API_ENDPOINTS.REGISTER, {
        email: email.toLowerCase(),
        name: name,
        role: role,
        position:position,
        working_hours: workingHours,
        hourly_rate: hourlyRate,
        currency: currency,
      });
      if (response.data?.status) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        getUsers(user?.data.id);
        setEmail("");
        setName("");
        setPosition("");
        setRole("user");
        setWorkingHours(8);
        setHourtlyRate(0);
        setCurrency("RON");
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    } finally {
      closeDialog();
      onClose();
    }
  };
  const handleSubmit = () => {
    if (!email.trim() || !isValidEmail(email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "warning",
      });
      return;
    }
    if (!name.trim()) {
      enqueueSnackbar("Please enter a valid name.", {
        variant: "warning",
      });
      return;
    }
    if (!role.trim()) {
      enqueueSnackbar("Please select a role.", {
        variant: "warning",
      });
      return;
    }
    if (!(workingHours >= 1) && !(workingHours <= 12)) {
      enqueueSnackbar("Working hours should be between 1 and 12.", {
        variant: "warning",
      });
      return;
    }
    if (hourlyRate <= 0) {
      enqueueSnackbar("Hourly rate should be bigger than 0.", {
        variant: "warning",
      });
      return;
    }
    if (currency.length > 3) {
      enqueueSnackbar("Currency should be a 3-char maximum text.", {
        variant: "warning",
      });
      return;
    }
    setDialogData({
      message: "Are you sure you want to create a new account ?",
      action: createNewAccount,
    });
    setOpenDialog(true);
  };
  const handleClose = () => {
    onClose();
    setEmail("");
    setName("");
    setPosition("");
    setRole("user");
    setWorkingHours(8);
    setHourtlyRate(0);
    setCurrency("RON");
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle textAlign={"center"} fontWeight={600}>
        Create a New Account
      </DialogTitle>
      <Divider sx={{ px: 30 }} />
      <DialogContent sx={{ gap: 3, display: "flex", flexDirection: "column" }}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          label="Contractual position"
          variant="outlined"
          fullWidth
          value={position}
          onChange={handlePosition}
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={handleRoleChange} label="Role">
            <MenuItem value="user">User</MenuItem>
            {["admin", "dev"].includes(user?.data.role)
              ? [
                  <MenuItem key={1} value="admin">Admin</MenuItem>,
                  <MenuItem key={2} value="supervisor">Supervisor</MenuItem>,
                  <MenuItem key={3} value="dev">Developer</MenuItem>,
                ]
              : null}
          </Select>
        </FormControl>
        <TextField
          label="Working Hours"
          variant="outlined"
          type="number"
          fullWidth
          value={workingHours}
          onChange={handleWorkingHours}
        />
        <TextField
          label="Hourly Rate"
          variant="outlined"
          type="number"
          fullWidth
          value={hourlyRate}
          onChange={handleHourlyRate}
        />
        <TextField
          label="Currency"
          variant="outlined"
          type="string"
          fullWidth
          value={currency}
          onChange={handleCurrency}
        />
      </DialogContent>
      <div
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: "flex-end",
          gap: 20,
        }}
      >
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Create
        </Button>
        <Button
          onClick={handleClose}
          color="error"
          variant="contained"
          endIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </div>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDialog}
        onConfirm={dialogData.action}
        message={dialogData.message}
      />
    </Dialog>
  );
};

export default CreateAccountModal;
