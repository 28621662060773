import React, { Fragment, useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Divider, ListItemButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useAuth } from "./AuthContext";
import HomeIcon from "@mui/icons-material/Home";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";
import { stringAvatar, API_ENDPOINTS } from "./vars";
import api from "./api";
import { enqueueSnackbar } from "notistack";
import ConfirmDialog from "./ConfirmDialog";
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LockResetIcon from "@mui/icons-material/LockReset";

const RightMenu = ({ open, onClose }) => {
    const { user, logout } = useAuth();
    const [selectedMenuItem, setSelectedMenuItem] = useState(
        localStorage.getItem("selectedMenuItem") || "dashboard"
    );
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState({
        message: "",
        action: () => {},
    });

    const closeDialog = () => setOpenDialog(false);
    const navigateHandle = (_location) => {
        localStorage.setItem("selectedMenuItem", _location);
        setSelectedMenuItem(_location);
        navigate("/" + _location);
        onClose();
    };
    const resetPassword = async (email) => {
        try {
            const response = await api.post(
                API_ENDPOINTS.REQUEST_NEW_PASSWORD,
                {
                    email: email,
                }
            );
            if (response.data?.status) {
                enqueueSnackbar(response.data?.message, { variant: "success" });
                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });
                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        } finally {
            setOpenDialog((prevValue) => !prevValue);
        }
    };
    const handleResetPassword = () => {
        setOpenDialog(true);
        setDialogData({
            message: "Send mail with password reset link?",
            action: () => resetPassword(user?.data?.email),
        });
    };
    useEffect(() => {
        return () => localStorage.removeItem("selectedMenuItem");
    }, []);
    const menuItems = [
        {
            id: "dashboard",
            text: "Dashboard",
            icon: <HomeIcon />,
        },
        {
            id: "time-management",
            text: "Time Management",
            icon: <TimelapseIcon />,
        },
        {
            id: "month-summary",
            text: "Month Summary",
            icon: <CalendarMonthIcon />,
        },
        {
            id: "work-reports",
            text: "Work Reports",
            icon: <AutoStoriesIcon />,
        },
    ];
    const adminMenu = [
        {
            id: "admin-time-management",
            text: "Time Management",
            icon: <TimelapseIcon />,
        },
        {
            id: "admin-month-summary",
            text: "Month Summary",
            icon: <CalendarMonthIcon />,
        },
        {
            id: "admin-work-reports",
            text: "Work Reports",
            icon: <AutoStoriesIcon />,
        },
        {
            id: "admin-manage-accounts",
            text: "Manage Accounts",
            icon: <ManageAccountsIcon />,
        },
        {
            id: "admin-status-requests",
            text: "Users Requests",
            icon: < ManageHistoryIcon/>,
        },
    ];
    return (
        user && (
            <Drawer anchor="right" open={open} onClose={onClose} elevation={24}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 10,
                        height: "100%",
                        width: "300px",
                    }}
                >
                    <ListItem
                        sx={{
                            gap: 3,
                        }}
                    >
                        <Avatar {...stringAvatar(user?.data?.name)} />
                        <ListItemText
                            primary={user?.data?.name}
                            secondary={user?.data?.email}
                        />
                    </ListItem>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            gap: 5,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                paddingLeft: 15,
                                marginTop: 10,
                                alignItems: "center",
                            }}
                        >
                            {" "}
                            <ListItemIcon>
                                <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="User panel"
                                primaryTypographyProps={{
                                    fontWeight: "bold",
                                }}
                            />
                        </div>
                        <Divider />
                        {menuItems.map((menuItem) => (
                            <ListItemButton
                                key={menuItem.id}
                                selected={selectedMenuItem === menuItem.id}
                                onClick={() => navigateHandle(menuItem.id)}
                                sx={{
                                    paddingLeft: 5,
                                }}
                            >
                                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                                <ListItemText primary={menuItem.text} />
                            </ListItemButton>
                        ))}

                        {["admin","supervisor","dev"].includes(user?.data?.role ) && (
                            <Fragment>
                                <div
                                    style={{
                                        display: "flex",
                                        paddingLeft: 15,
                                        marginTop: 10,
                                        alignItems: "center",
                                    }}
                                >
                                    {" "}
                                    <ListItemIcon>
                                        <AdminPanelSettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Admin panel"
                                        primaryTypographyProps={{
                                            fontWeight: "bold",
                                        }}
                                    />
                                </div>

                                <Divider />
                                {adminMenu.map((menuItem) => (
                                    <ListItemButton
                                        key={menuItem.id}
                                        selected={
                                            selectedMenuItem === menuItem.id
                                        }
                                        onClick={() =>
                                            navigateHandle(menuItem.id)
                                        }
                                        sx={{
                                            paddingLeft: 5,
                                        }}
                                    >
                                        <ListItemIcon>
                                            {menuItem.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={menuItem.text} />
                                    </ListItemButton>
                                ))}
                            </Fragment>
                        )}
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <ListItemButton
                                    onClick={handleResetPassword}
                                    sx={{
                                        position: "relative",
                                        bottom: 0,
                                    }}
                                >
                                    <ListItemIcon>
                                        <LockResetIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Reset password" />
                                </ListItemButton>

                                <Divider />
                                <ListItemButton
                                    onClick={logout}
                                    sx={{
                                        position: "relative",
                                        bottom: 0,
                                    }}
                                >
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItemButton>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmDialog
                    open={openDialog}
                    onClose={closeDialog}
                    onConfirm={dialogData.action}
                    message={dialogData.message}
                />
            </Drawer>
        )
    );
};

export default RightMenu;
