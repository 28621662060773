import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Typography,
    Grid,
    Paper,
    Box,
    Divider,
} from "@mui/material";
import api from "../utils/api";
import { API_ENDPOINTS, beautyStatus } from "../utils/vars";
import { useAuth } from "../utils/AuthContext";
import LiveClock from "../utils/LiveClock";
import TimeManagement from "./TimeManagement";
import StatusHistory from "../utils/StatusHistory";
import RequestReport from "../utils/RequestsReport";

function Dashboard() {
    const { user } = useAuth();
    const [status, setStatus] = useState("loading...");
    const [historyData, setHistoryData] = useState([]);
    const [requestsReportData, setRequestReportData] = useState([]);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const handleChangeStatus = async (id, newStatus) => {
        try {
            await api.post(API_ENDPOINTS.CHANGE_STATUS, {
                user_id: id,
                new_status: newStatus,
            });
            getStatus(user?.data.id);
            getStatusHistory(user?.data.id);
        } catch (error) {
            console.error("Error changing status:", error);
        }
    };

    const getStatus = async (id) => {
        try {
            const response = await api.post(API_ENDPOINTS.GET_STATUS, {
                user_id: id,
            });
            setStatus(response.data?.status);
        } catch (error) {
            console.error("Error changing status:", error);
        }
    };
    const getStatusHistory = async (id) => {
        try {
            const response = await api.post(API_ENDPOINTS.STATUS_HISTORY, {
                user_id: id,
            });
            if (response.data?.results) setHistoryData(response.data.results);
        } catch (error) {
            console.error("Error changing status:", error);
        }
    };
    const getRequestsReport = async (id) => {
        try {
            const response = await api.post(API_ENDPOINTS.REQUESTS_REPORT, {
                user_id: id,
            });
            if (response.data?.requests)
                setRequestReportData(response.data.requests);
        } catch (error) {
            console.error("Error changing status:", error);
        }
    };
    useEffect(() => {
        if (user?.data) {
            getStatus(user?.data.id);
            getStatusHistory(user?.data.id);
            getRequestsReport(user?.data.id);
        }
    }, [user?.data]);
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, []);
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100vw",
            }}
        >
            <Container maxWidth="md" sx={{ my: 15 }}>
                <Typography variant="h5" align="right" gutterBottom>
                    <LiveClock />
                </Typography>

                {user?.data && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper
                                elevation={5}
                                sx={{
                                    borderRadius: 4,
                                }}
                            >
                                <Box p={3}>
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        gutterBottom
                                    >
                                        Welcome, {user.data.name}!
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />

                                    <Typography
                                        variant="h5"
                                        align="center"
                                        gutterBottom
                                    >
                                        Status: {beautyStatus(status)}
                                    </Typography>

                                    <Grid container spacing={2} mt={2}>
                                        {status !== "clocked_in" && (
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    fullWidth
                                                    onClick={() =>
                                                        handleChangeStatus(
                                                            user?.data.id,
                                                            "clocked_in"
                                                        )
                                                    }
                                                >
                                                    Clock In
                                                </Button>
                                            </Grid>
                                        )}
                                        {status === "clocked_in" && (
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    fullWidth
                                                    onClick={() =>
                                                        handleChangeStatus(
                                                            user?.data.id,
                                                            "break"
                                                        )
                                                    }
                                                >
                                                    Break
                                                </Button>
                                            </Grid>
                                        )}
                                        {status === "clocked_in" && (
                                            <Grid
                                                item
                                                xs={
                                                    status === "clocked_in"
                                                        ? 6
                                                        : 12
                                                }
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() =>
                                                        handleChangeStatus(
                                                            user?.data.id,
                                                            "clocked_out"
                                                        )
                                                    }
                                                >
                                                    Clock Out
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Container>
            <TimeManagement userStatus={status} dashboard={true} />
            
            <Box
                sx={{
                    display: "flex",
                    flexDirection: windowSize.width > 960 ? "row" : "column",
                    gap: windowSize.width > 960 ? 20 : 0,
                    maxWidth: "95%",
                }}
            >
                <StatusHistory
                    data={historyData}
                    refreshRequests={() => getRequestsReport(user?.data.id)}
                />
                <RequestReport data={requestsReportData} />
            </Box>
        </Box>
    );
}

export default Dashboard;
