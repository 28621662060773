import React, { Fragment, useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Container,
  Avatar,
  Box,
  Button,
  ListItemText,
  Grid,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "../../utils/AuthContext";
import api from "../../utils/api";
import { API_ENDPOINTS, stringAvatar } from "../../utils/vars";
import { enqueueSnackbar } from "notistack";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import LockResetIcon from "@mui/icons-material/LockReset";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../utils/ConfirmDialog";
import CreateAccountModal from "../../utils/CreateAccountModal";
import EditUserModal from "../../utils/EditUserModal";
import HrHistoryModal from "../../utils/HrHistoryModal";
import LiveClock from "../../utils/LiveClock";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import BlockIcon from "@mui/icons-material/Block";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
const AdminMA = () => {
  const { user } = useAuth();
  const widthBreakPoint = 1520;
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState({
    newAccount: false,
    editUser: false,
    hrHistory:false
  });
  const [modalData, setModalData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    message: "",
    action: () => {},
  });
  const [windowSize, setWindowSize] = useState({
    width: 1024,
    height: 860,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);
  const handleAccountStatus = (user) => {
    setDialogData({
      message: `Are you sure you want to set this account ${
        user?.active ? "inactive" : "active"
      } ?`,
      action: () => changeAccountStatus(user),
    });
    setOpenDialog((prevValue) => !prevValue);
  };
  const changeAccountStatus = async (_user) => {
    try {
      const response = await api.post(API_ENDPOINTS.CHANGE_ACCOUNT_STATUS, {
        id: _user?.id,
        new_status: !_user?.active,
      });
      if (response.data?.status) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        getUsers(user?.data?.id);
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    } finally {
      setOpenDialog((prevValue) => !prevValue);
    }
  };
  const closeModal = () => setOpenModal({ newAccount: false, editUser: false, hrHistory:false });

  const handleResetPassword = (user) => {
    setDialogData({
      message: "Are you sure you want to reset the password for this user?",
      action: () => resetPassword(user),
    });
    setOpenDialog((prevValue) => !prevValue);
  };
  const handleHrHistory = (user) => {
    setModalData(user);
    setOpenModal((prevValue) => ({ ...prevValue, hrHistory: true }));
  };
  const handleDeleteUser = (user) => {
    setDialogData({
      message:
        "Are you sure you want to delete this user? This includes all data about this info.",
      action: () => deleteUser(user),
    });
    setOpenDialog((prevValue) => !prevValue);
  };
  const handleNewAccount = () =>
    setOpenModal((prevValue) => ({ ...prevValue, newAccount: true }));
  const handleEditUser = (user) => {
    setModalData(user);
    setOpenModal((prevValue) => ({ ...prevValue, editUser: true }));
  };
  const deleteUser = async (_user) => {
    try {
      const response = await api.post(API_ENDPOINTS.DELETE_USER, {
        id: _user?.id,
      });
      if (response.data?.status) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        getUsers(user?.data?.id);
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    } finally {
      setOpenDialog((prevValue) => !prevValue);
    }
  };
  const resetPassword = async (_user) => {
    try {
      const response = await api.post(API_ENDPOINTS.REQUEST_NEW_PASSWORD, {
        email: _user?.email,
      });
      if (response.data?.status) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        getUsers(user?.data?.id);
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    } finally {
      setOpenDialog((prevValue) => !prevValue);
    }
  };
  const closeDialog = () => setOpenDialog(false);
  const getUsers = async (id) => {
    try {
      const response = await api.post(API_ENDPOINTS.GET_USERS, {
        user_id: id,
        used_at: "ma",
      });
      if (response.data?.users) {
        enqueueSnackbar(response.data?.message, { variant: "success" });
        setUsers(response.data?.users);
        return;
      } else {
        enqueueSnackbar(response.data?.message, { variant: "info" });
        return;
      }
    } catch (error) {
      enqueueSnackbar("Server error, please contact admin!", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    if (user?.data.id) getUsers(user?.data?.id);

    // eslint-disable-next-line
  }, [user]);
  return (
    <Fragment>
      <Container sx={{ my: 15 }} className="manage-users-container">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 2,
          }}
        >
          <LiveClock />
        </Grid>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant={windowSize.width > widthBreakPoint ? "h4" : "h6"}
              sx={{ mt: 3, mb: 2 }}
            >
              Accounts:
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{
                px: windowSize.width > widthBreakPoint ? 4 : 1,
                mt: 3,
                mb: 2,
              }}
              endIcon={<PersonAddAlt1Icon />}
              onClick={handleNewAccount}
            >
              {windowSize.width.width > widthBreakPoint
                ? " Add new account"
                : "New account"}
            </Button>
          </div>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 500 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 500 }}
              transition={{
                duration: 0.7,
                ease: "easeInOut",
              }}
            >
              {users.map((user) => {
                return (
                  <Paper
                    key={user.id}
                    elevation={4}
                    sx={{
                      padding: 2,
                      marginBottom: 3,
                      borderRadius: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems:
                        windowSize.width > widthBreakPoint
                          ? "center"
                          : "flex-start",
                      flexDirection:
                        windowSize.width > widthBreakPoint ? "row" : "column",
                      backgroundColor: user.active ? "" : "gainsboro",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      <Avatar {...stringAvatar(user?.name)} />
                      <Box>
                        <ListItemText
                          primary={user?.name}
                          secondary={user?.email}
                        />
                        <Typography
                          fontSize={15}
                          fontWeight={500}
                          color={user.active ? "green" : "red"}
                        >
                          {user?.active ? "Active" : "Inactive"} account
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      gap={windowSize.width > widthBreakPoint ? 3 : 1}
                      display={"flex"}
                      sx={{
                        flexDirection:
                          windowSize.width > widthBreakPoint ? "row" : "column",
                        marginTop: windowSize.width > widthBreakPoint ? 0 : 2,
                        width:
                          windowSize.width > widthBreakPoint ? "auto" : "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        fullWidth={windowSize.width < widthBreakPoint}
                        sx={{
                          px: windowSize.width > widthBreakPoint ? 3 : 1,

                          backgroundColor: "#017d77",
                          ":hover": {
                            backgroundColor: "#18c9c1",
                          },
                        }}
                        endIcon={<CurrencyExchangeIcon />}
                        onClick={() => handleHrHistory(user)}
                        disabled={!user.active}
                      >
                        HR history
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth={windowSize.width < widthBreakPoint}
                        color="success"
                        sx={{
                          px: windowSize.width > widthBreakPoint ? 3 : 1,
                        }}
                        endIcon={<EditIcon />}
                        onClick={() => handleEditUser(user)}
                        disabled={!user.active}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth={windowSize.width < widthBreakPoint}
                        color="secondary"
                        sx={{
                          px: windowSize.width > widthBreakPoint ? 3 : 1,
                        }}
                        endIcon={<LockResetIcon />}
                        onClick={() => handleResetPassword(user)}
                        disabled={!user.active}
                      >
                        Reset password
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth={windowSize.width < widthBreakPoint}
                        color={user.active ? "warning" : "success"}
                        sx={{
                          px: windowSize.width > widthBreakPoint ? 3 : 1,
                          width:
                            windowSize.width > widthBreakPoint ? 160 : "auto",
                        }}
                        endIcon={
                          user.active ? <BlockIcon /> : <RotateLeftIcon />
                        }
                        onClick={() => handleAccountStatus(user)}
                      >
                        {!user?.active ? "Activate" : "Deactivate"}
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth={windowSize.width < widthBreakPoint}
                        color="error"
                        sx={{
                          px: windowSize.width > widthBreakPoint ? 3 : 1,
                        }}
                        endIcon={<PersonRemoveIcon />}
                        onClick={() => handleDeleteUser(user)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Paper>
                );
              })}
            </motion.div>
          </AnimatePresence>
        </div>
      </Container>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDialog}
        onConfirm={dialogData.action}
        message={dialogData.message}
      />
      <CreateAccountModal
        open={openModal.newAccount}
        onClose={closeModal}
        getUsers={getUsers}
      />
      <EditUserModal
        open={openModal.editUser}
        onClose={closeModal}
        userData={modalData}
        getUsers={getUsers}
      />
      <HrHistoryModal
        open={openModal.hrHistory}
        onClose={closeModal}
        userData={modalData}
        getUsers={getUsers}
      />
    </Fragment>
  );
};

export default AdminMA;
