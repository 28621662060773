import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { beautyStatus, sec2time } from "./vars";
import api from "./api";
import { API_ENDPOINTS } from "./vars";
import ReactLoading from "react-loading";

function PopUp({ open, onClose, data }) {
    const [content, setContent] = useState({ workingTime: "", breakTime: "" });
    const [isLoading, setIsLoading] = useState(true);
    const getTimes = async (id) => {
        try {
            const response = await api.post(API_ENDPOINTS.GET_TIMES, {
                user_id: id,
            });
            const _data = {
                breakTime: sec2time(response.data?.times.break_time),
                workingTime: sec2time(response.data?.times.working_time),
            };
            setContent(_data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error changing status:", error);
        }
    };
    useEffect(() => {
        let interval = 0;
        if (data?.actualDay) {
            if (open) {
                interval = setInterval(() => {
                    getTimes(data?.id);
                }, 1000);
            } else {
                clearInterval(interval);
                setIsLoading(true);
                setContent({ workingTime: "", breakTime: "" });
            }
        } else {
            setContent(data);
            setIsLoading(false);
        }
        return () => {
            clearInterval(interval);
            setContent({ workingTime: "", breakTime: "" });
            setIsLoading(true);
        };

        // eslint-disable-next-line
    }, [open]);
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                setIsLoading(true);
            }}
            data={data}
        >
            <DialogTitle sx={{ fontWeight: "bold", fontSize: 24 }}>
                {data?.title}
            </DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ReactLoading type="bubbles" color="#000" />
                    </div>
                ) : data?.isData ? (
                    <Fragment>
                        <Typography>
                            Working time: {content.workingTime}
                        </Typography>
                        <Typography>Break time: {content.breakTime}</Typography>
                        {data?.status && (
                            <Typography>
                                Status: {beautyStatus(data?.status)}
                            </Typography>
                        )}
                    </Fragment>
                ) : data?.isWeekend ? (
                    <Typography> Weekend day here.</Typography>
                ) : (
                    <Typography> There is no data for this date.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PopUp;
