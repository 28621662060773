import React,{useEffect, useState} from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Button,
    Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";



const rows = [
    { id: 1, color: "grey", information: "Is weekend" },
    { id: 2, color: "green", information: "Working day, more than 8 hours" },
    { id: 3, color: "red", information: "Working day, less than 8 hours" },
    { id: 4, color: "green-clocked-in", information: "Current day, online and working" },
    { id: 5, color: "yellow", information: "Current day, in break" },
    { id: 6, color: "red", information: "Current day, clocked out" },
    { id: 7, color: "black", information: "No data for this date" },
    { id: 8, color: "blue-in-progress", information: "Free day in pending" },
    { id: 9, color: "blue-accepted", information: "Free day accepted" },
    { id: 10, color: "purple-rejected", information: "Free day rejected" },
];

function TimeLegendModal({ open, onClose }) {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    const columns = [
        {
            field: "color",
            headerName: "Color",
            width:  70,
            sortable: false,
            renderCell: (params) => (
                <div
                    className={`${params.value}-background`}
                    style={{ width: "100%", height: "100%", padding: 0, borderRightColor:'white', borderRightWidth:'1px', borderRightStyle:'solid'}}
                >
                    <div></div>
                </div>
            ),
        },
        {
            field: "information",
            headerName: "Information",
            sortable: false,
            width: windowSize.width > 960 ? 280 : 230,
            renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: windowSize.width > 960 ? 16 : 12
                  }}
                >
                  {params.value}
                </div>
            )
        },
    ];


    useEffect(()=>{
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    },[])


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle textAlign={"center"} fontWeight={600}>
                Colors legend
            </DialogTitle>
            <Divider />
            <DialogContent sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
            }}>
                <div style={{ width: "fit-content" ,padding:'0 100px' }}>
                    <DataGrid
                        className="legend-table"
                        rows={rows}
                        columns={columns}
                        checkboxSelection={false}
                        autoHeight
                        pageSize={rows.length}
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableDensitySelector
                        disableMultipleColumnsSorting
                        hideFooterPagination
                        hideFooterRowCount
                        sx={{
                            color: "white",
                            backgroundColor: "rgba(10,10,10,.9)",
                        }}
                    />
                </div>
            </DialogContent>
           <Box sx={{
            my:3,
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-end',
            mx:2
           }}>
           <Button
                fullWidth
                onClick={onClose}
                color="error"
                variant="contained"
                endIcon={<CloseIcon />}
                startIcon={<CloseIcon />}
                sx={{
                    justifyContent:'space-between'
                }}
            >
                Close
            </Button>
           </Box>
        </Dialog>
    );
}

export default TimeLegendModal;
