import React, { useEffect, useState } from "react";
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
    IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit"; // Import the edit icon
import { format } from "date-fns";
import RequestStatusChangeModal from "./RequestStatusChangeModal";

const StatusHistory = ({ data = [], refreshRequests }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [modalItem, setModalItem] = useState({});
    const handleEdit = (item) => {
        setModalItem(item);
        setOpen(true);
    };
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        if (data.length > 0) setIsLoading(false);
    }, [data]);
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, []);
    return (
        !isLoading && (
            <Paper
                elevation={10}
                sx={{
                    px: windowSize.width > 960 ? 5 : 2,
                    py: windowSize.width > 960 ? 4 : 2,
                    mb: windowSize.width > 960 ?  15 : 8,
                    borderRadius: 4,
                }}
            >
                <Typography variant="h5">Status History</Typography>
                <List>
                    {data.map((item, index) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: 16,
                            }}
                            key={index}
                        >
                            <Paper
                                elevation={5}
                                className={
                                    item.current_status === "clocked_in"
                                        ? "green-background"
                                        : item.current_status === "break"
                                        ? "orange-background"
                                        : "red-background "
                                }
                                sx={{
                                    borderRadius: 3,
                                    color:
                                        item.current_status === "clocked_in"
                                            ? "#FFF"
                                            : item.current_status === "break"
                                            ? "#333"
                                            : "#FFF",
                                    flex: 1,
                                }}
                            >
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            item.current_status === "clocked_in"
                                                ? `Clocked in at ${format(
                                                      new Date(item.changed_at),
                                                      "hh:mm aa"
                                                  )}`
                                                : item.current_status ===
                                                  "break"
                                                ? `Break at ${format(
                                                      new Date(item.changed_at),
                                                      "hh:mm aa"
                                                  )}`
                                                : `Clocked out at ${format(
                                                      new Date(item.changed_at),
                                                      "hh:mm aa"
                                                  )}`
                                        }
                                        sx={{
                                            pr: windowSize.width > 960 ? 50 : 10,
                                        }}
                                    />
                                </ListItem>
                            </Paper>
                            <IconButton
                                aria-label="Edit"
                                color="primary"
                                onClick={() => handleEdit(item)}
                                sx={{
                                    width: 50,
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </div>
                    ))}
                </List>{" "}
                {modalItem && (
                    <RequestStatusChangeModal
                        open={open}
                        onClose={() => setOpen(false)}
                        item={modalItem}
                        refreshRequests={refreshRequests}
                    />
                )}
            </Paper>
        )
    );
};

export default StatusHistory;
