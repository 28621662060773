import React, { useEffect, useState } from "react";
import { Button, Modal, Box, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import api from "./api";
import { API_ENDPOINTS } from "./vars";
import { useAuth } from "./AuthContext";
import { format, getMonth, getYear, getDate, isToday } from "date-fns";
import { enqueueSnackbar } from "notistack";

function CustomModal({ open, onClose, report, getReports, getDailyReport }) {
    const [reportText, setReportText] = useState(report?.report);
    const { user } = useAuth();
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    const handleCloseModal = () => {
        onClose();
    };
    const updateReport = async (id, reportID) => {
        try {
            const response = await api.post(API_ENDPOINTS.UPDATE_REPORT, {
                user_id: id,
                id: reportID,
                report: reportText,
            });
            if (response.data?.status) {
                enqueueSnackbar(response.data?.message, { variant: "success" });

                return;
            } else {
                enqueueSnackbar(response.data?.message, { variant: "info" });

                return;
            }
        } catch (error) {
            enqueueSnackbar("Server error, please contact admin!", {
                variant: "error",
            });
        } finally {
            handleCloseModal();
            const reportDate = new Date(report?.created_at);
            getReports(
                user?.data?.id,
                getMonth(reportDate),
                getYear(reportDate)
            );
            if (isToday(reportDate)) {
                getDailyReport(
                    user?.data?.id,
                    getMonth(reportDate),
                    getYear(reportDate),
                    getDate(reportDate)
                );
            }
        }
    };
    const handleChange = (ev) => {
        setReportText(ev.currentTarget.value);
    };
    useEffect(() => {
        setReportText(report?.report);
    }, [report]);
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };

        // eslint-disable-next-line
    }, []);

    const handleSaveOnKeys = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            event.preventDefault();
            updateReport(user?.data.id, report?.id);
        }
    };
    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    bgcolor: "white",
                    boxShadow: 24,
                    p: windowSize.width > 960 ? "10px 20px" : "2px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: 3,
                }}
            >
                <Typography
                    fontWeight={700}
                    fontSize={24}
                    px={2}
                    py={2}
                    textAlign={"center"}
                >
                    Edit report from:{" "}
                    {format(
                        new Date(
                            report?.created_at.includes("GMT")
                                ? report?.created_at.slice(0, -3)
                                : report?.created_at
                        ),
                        "MMMM dd yyyy"
                    )}
                </Typography>
                <Typography
                    fontWeight={500}
                    fontSize={16}
                    fontStyle={"italic"}
                    pt={2}
                    textAlign={"left"}
                >
                    Created at:{" "}
                    {format(
                        new Date(
                            report.created_at.substring(
                                0,
                                report.created_at.length - 4
                            )
                        ),
                        "do MMM hh:mm:ss a"
                    )}
                </Typography>
                {report?.updated_at && (
                    <Typography
                        fontWeight={500}
                        fontSize={16}
                        fontStyle={"italic"}
                        textAlign={"left"}
                    >
                        Last update:{" "}
                        {format(
                            new Date(
                                report.updated_at.substring(
                                    0,
                                    report.updated_at.length - 4
                                )
                            ),
                            "do MMM hh:mm:ss a"
                        )}
                    </Typography>
                )}

                <textarea
                    value={reportText}
                    rows={15}
                    className="custom-text-area"
                    onChange={handleChange}
                    onKeyDown={handleSaveOnKeys}
                ></textarea>
                <div
                    style={{
                        display: "flex",
                        gap: 15,
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                            mt: 3,
                            px: 5,
                        }}
                        endIcon={<SaveIcon />}
                        onClick={() => updateReport(user?.data.id, report?.id)}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{
                            mt: 3,
                            px: 5,
                            alignSelf: "flex-end",
                        }}
                        endIcon={<CancelIcon />}
                        onClick={handleCloseModal}
                    >
                        Close
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}

export default CustomModal;
