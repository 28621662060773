import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    CssBaseline,
    Paper,
    Link,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import { API_ENDPOINTS, isValidEmail } from "../utils/vars";
import { useAuth } from "../utils/AuthContext";
import LostPasswordModal from "../utils/LostPasswordModal";
import { enqueueSnackbar } from "notistack";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
function Login() {
    const { user, login } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [lostPassword, setLostPassword] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const navigate = useNavigate();

    function Copyright(props) {
        return (
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                {...props}
            >
                {"Copyright © "}
                <Link color="inherit" href="https://tron.ro/">
                    TRON
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        );
    }

    const handleLogin = async () => {
        if (!email.trim() || !isValidEmail(email)) {
            enqueueSnackbar("Please enter a valid email address.", {
                variant: "error",
            });

            return;
        }

        if (!password.trim()) {
            enqueueSnackbar("Please enter your password.", {
                variant: "error",
            });
            return;
        }
        try {
            const response = await api.post(API_ENDPOINTS.LOGIN, {
                email: email.toLowerCase(),
                password: password,
            });
            if (response.data?.token) {
                enqueueSnackbar(response.data?.message, { variant: "success" });
                login(response.data);
                navigate("/dashboard");
            } else {
                enqueueSnackbar(response.data?.message, { variant: "warning" });

                return;
            }
        } catch (error) {
            console.error("Login error:", error);
            enqueueSnackbar("Login failed. Please check your credentials.", {
                variant: "error",
            });
        }
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleLogin();
        }
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        if (user?.data.id) {
            navigate('/dashboard')
        }
    
        // eslint-disable-next-line
    }, [user]);

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage:
                        "url(https://source.unsplash.com/random?wallpapers)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) =>
                        t.palette.mode === "light"
                            ? t.palette.grey[50]
                            : t.palette.grey[900],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
            >
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <img
                        style={{
                            width: 128,
                            height: 128,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                        }}
                        src={
                            "https://tron.ro/wp-content/uploads/2020/07/tron-logo3-1.svg"
                        }
                        alt="logo"
                    ></img>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(ev) => setEmail(ev.target.value)}
                        />
                        <FormControl
                            margin="normal"
                            fullWidth
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                name="password"
                                label="Password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(ev) => setPassword(ev.target.value)}
                                onKeyDown={handleEnterKeyPress}
                            />
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleLogin}
                            sx={{ mt: 3, mb: 2, px: 10 }}
                        >
                            Login
                        </Button>
                        <Grid container>
                            <Grid
                                item
                                xs
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <Link
                                    variant="body2"
                                    component={"button"}
                                    onClick={() => setLostPassword(true)}
                                >
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Grid>
            <LostPasswordModal
                open={lostPassword}
                onClose={() => setLostPassword(false)}
            />
        </Grid>
    );
}

export default Login;
