import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, Typography } from "@mui/material";
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    isWeekend,
    getMonth,
    getYear,
    getDay,
} from "date-fns";
import { useAuth } from "../utils/AuthContext";
import api from "../utils/api";
import { API_ENDPOINTS, sec2time } from "../utils/vars";
import Loading from "../utils/Loading";
import LiveClock from "../utils/LiveClock";
import MonthGraph from "../utils/MonthGraph";

const MonthSummary = () => {
    const { user } = useAuth();
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [monthData, setMonthData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [workingHours, setWorkingHours] = useState(8);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [xAxisLabels, setXAxisLabels] = useState([]);
    const [graphData, setSeries] = useState({
        workingTime: [],
        breakTime: [],
    });
    const handlePrevMonth = () => {
        const newDate = subMonths(currentMonth, 1);
        setCurrentMonth(newDate);

        getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
        getGraphData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
    };
    const handleNextMonth = () => {
        const newDate = addMonths(currentMonth, 1);
        setCurrentMonth(newDate);

        getMonthData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
        getGraphData(user?.data.id, getMonth(newDate) + 1, getYear(newDate));
    };
    const getRowId = (row) => row.id;
    const getMonthData = async (user, month, year) => {
        setIsLoading(true);
        if (user) {
            try {
                const data = {
                    user_id: user,
                    month: month,
                    year: year,
                };
                const response = await api.post(
                    API_ENDPOINTS.GET_USER_MONTH_STATISTICS,
                    data
                );
                setMonthData({ ...response.data?.user_statistics });
                setIsLoading(false);
            } catch (error) {
                console.error("User month data error:", error);
            }
        }
    };
    const handleRefreshData = () => {
        getMonthData(
            user?.data.id,
            getMonth(currentMonth) + 1,
            getYear(currentMonth)
        );
    };
    useEffect(() => {
        getMonthData(
            user?.data.id,
            getMonth(currentMonth) + 1,
            getYear(currentMonth)
        );
        getStatus(user?.data.id);
        getGraphData(
            user?.data.id,
            getMonth(currentMonth) + 1,
            getYear(currentMonth)
        );
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, [user]);
    function countBusinessDaysInMonth(year, month) {
        const firstDayOfMonth = startOfMonth(new Date(year, month - 1, 1));
        const lastDayOfMonth = endOfMonth(new Date(year, month - 1, 1));

        const daysOfMonth = eachDayOfInterval({
            start: firstDayOfMonth,
            end: lastDayOfMonth,
        });

        let businessDayCount = 0;

        for (const day of daysOfMonth) {
            if (!isWeekend(day)) {
                businessDayCount++;
            }
        }

        return businessDayCount;
    }
    const getStatus = async (id) => {
        if (id) {
            try {
                const response = await api.post(API_ENDPOINTS.GET_STATUS, {
                    user_id: id,
                });
                setWorkingHours(response.data.working_hours);
            } catch (error) {
                // Handle error (e.g., show error message)
                console.error("Error changing status:", error);
            }
        }
    };
    const getGraphData = async (user, month, year) => {
        if (user) {
            try {
                const data = {
                    user_id: user,
                    month: month,
                    year: year,
                };
                const response = await api.post(
                    API_ENDPOINTS.GET_USER_MONTH_DATA,
                    data
                );
                resolveData(
                    { ...response.data?.data_by_user },
                    new Date(year, month - 1)
                );
            } catch (error) {
                console.error("User month data error:", error);
            }
        }
    };
    const resolveData = (data, _date = currentMonth) => {
        const startDate = startOfMonth(_date);
        const endDate = endOfMonth(_date);
        const allDatesInMonth = eachDayOfInterval({
            start: startDate,
            end: endDate,
        });

        const times = {
            workingTime: [],
            breakTime: [],
        };

        const labels = [];
        allDatesInMonth.map((day, index) => {
            const dayOfWeek = getDay(day);
            const isSunday = dayOfWeek === 0;
            const isSaturday = dayOfWeek === 6;
            if (!isSunday && !isSaturday) {
                if (data[index + 1]?.working_time !== undefined)
                    times.workingTime.push(
                        data[index + 1]?.working_time / 3600
                    );
                else {
                    times.workingTime.push(null);
                }
                if (data[index + 1]?.break_time !== undefined)
                    times.breakTime.push(data[index + 1]?.break_time / 3600);
                else {
                    times.breakTime.push(null);
                }
                labels.push(format(new Date(day), "dd MMM"));
            }
            return 0;
        });
        const series =
            Object.keys(data).length > 0
                ? [
                      {
                          data: times?.workingTime,
                          label: "Working time",
                          color: "green",
                      },
                      {
                          data: times?.breakTime,
                          label: "Break time",
                          color: "orange",
                      },
                  ]
                : [];
        setSeries(series);
        setXAxisLabels(labels);
    };
    const columns = [
        { field: "user_id", headerName: "User", width: 200, sortable: false },
        {
            field: "business_days",
            headerName: "Business days",
            width: windowSize.width < 1800 ? 150 : 200,
        },
        {
            field: "worked_days",
            headerName: "Worked days",
            width: windowSize.width < 1800 ? 150 : 200,
        },
        {
            field: "working_time",
            headerName: "Working Time",
            width: windowSize.width < 1800 ? 150 : 200,
        },
        {
            field: "break_time",
            headerName: "Break Time",
            width: windowSize.width < 1800 ? 150 : 200,
        },
        {
            field: "average_working_time",
            headerName: "Avg. working time",
            width:
                windowSize.width < 2100
                    ? windowSize.width < 1800
                        ? 160
                        : 200
                    : 250,
        },
        {
            field: "average_break_time",
            headerName: "Avg. break time",
            width:
                windowSize.width < 2100
                    ? windowSize.width < 1800
                        ? 160
                        : 200
                    : 250,
        },
        {
            field: "average_clock_in_time",
            headerName: "Avg. clock in timestamp",
            width:
                windowSize.width < 2100
                    ? windowSize.width < 1800
                        ? 160
                        : 200
                    : 250,
        },
        {
            field: "average_clock_out_time",
            headerName: "Avg. clock out timestamp",
            width:
                windowSize.width < 2100
                    ? windowSize.width < 1800
                        ? 160
                        : 200
                    : 250,
        },
    ];
    const rows = Object.keys(monthData).map(() => {
        const newRow = {
            id: user?.data.id,
            business_days: countBusinessDaysInMonth(
                getYear(currentMonth),
                getMonth(currentMonth)
            ),
            working_time: sec2time(monthData?.working_time),
            break_time: sec2time(monthData?.break_time),
            average_working_time: sec2time(monthData?.avg_working_time),
            worked_days: (
                monthData?.working_time /
                (workingHours * 3600)
            ).toFixed(2),
            average_break_time: sec2time(monthData?.avg_break_time),
            average_clock_in_time: monthData?.avg_clock_in,
            average_clock_out_time: monthData?.avg_clock_out,
        };
        newRow.user_id = user?.data.name;
        return newRow;
    });

    return isLoading ? (
        <Loading />
    ) : (
        <div className="table-container">
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                marginY={15}
                className="month-selector-container"
            >
                <Button
                    sx={{
                        fontWeight: "bold",
                    }}
                    onClick={handlePrevMonth}
                >
                    Prev Month
                </Button>
                <Typography variant="h4">
                    {format(currentMonth, "MMMM yyyy")}
                </Typography>
                <Button
                    sx={{
                        fontWeight: "bold",
                    }}
                    onClick={handleNextMonth}
                >
                    Next Month
                </Button>
            </Grid>

            <Typography fontWeight={"bold"} className="info-text">
                ***This statistics does not include the current day***
            </Typography>
            <Grid
                display="grid"
                alignItems="center"
                justifyContent="start"
                gridTemplateColumns="repeat(2, 1fr)"
                className="live-clock-container"
            >
                <Button
                    sx={{
                        fontWeight: "bold",
                        width: 150,
                        my: 2,
                    }}
                    variant="contained"
                    color="primary"
                    className="refresh-data-button"
                    onClick={handleRefreshData}
                >
                    Refresh Data
                </Button>
                <LiveClock />
            </Grid>

            <div>
                <DataGrid
                    className={["table-toolbar", "table-toolbar-user"]}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    getRowId={getRowId}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 1 },
                        },
                    }}
                    pageSizeOptions={[1]}
                    sx={{
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "rgba(10,10,10,.9)",
                        borderRadius: 4,
                    }}
                />
            </div>
            {graphData && xAxisLabels.length > 0 ? (
                <MonthGraph xLabels={xAxisLabels} series={graphData} />
            ) : (
                <Fragment />
            )}
        </div>
    );
};

export default MonthSummary;
