import React, { createContext, useContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import api from "./api";
import { API_ENDPOINTS } from "./vars";
import { enqueueSnackbar } from "notistack";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const login = (userData) => {
        if (userData?.token) {
            localStorage.setItem("token", userData?.token);
            const data = jwt_decode(userData.token);
            setUser(data);
            setLoading(false)
        } else {
            setUser(userData);
            setLoading(false);
        }
    };
    const logout = () => {
        try {
            const token = localStorage.getItem("token");
            if (token !== "undefined" && token !== null) {
                const userData = jwt_decode(token);
                if (removeToken(userData.data.id, token)) {
                    setUser(null);
                    localStorage.removeItem("selectedMenuItem");
                    navigate("/login");
                    localStorage.removeItem("token");
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const removeToken = async (id, token) => {
        try {
            const response = await api.post(API_ENDPOINTS.LOGOUT, {
                user_id: id,
                token: token,
            });
            return response?.data.status;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const checkToken = async (userData, token) => {
            try {
                const currentTimestamp = Math.floor(Date.now() / 1000);
                if (userData?.exp > currentTimestamp) {
                    const response = await api.post(API_ENDPOINTS.CHECK_TOKEN, {
                        user_id: userData.data.id,
                        token: token,
                    });
                    if (response.data.status) {
                        login(userData);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        navigate("/");
                    }
                }else{
                    enqueueSnackbar('Token expired, please log in back!', {
                        variant: "warning",
                        
                    })
                    logout()
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (!window.location.href.includes("/reset-password/")) {
            const token = localStorage.getItem("token");
            if (token === "undefined" || token === null) {
                setLoading(false);
                navigate("/");
            } else {
                try {
                    const userData = jwt_decode(token);
                    checkToken(userData, token);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        // eslint-disable-next-line
    }, []);
    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
}
